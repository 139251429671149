import React from 'react';
import './Modal.css';

import ModalPortal from './ModalPortal';

const Modal = props => {
  return (
    <ModalPortal>
      <div className="modal-container">
      <div className={`modal-dimmer`} />
        <div className={`modal-el ${props.className || ''}`}>
          {props.children}
        </div>
      </div>
    </ModalPortal>
  )
};

export default Modal;