import axios from 'axios'

export async function createPursue(instance, pursue) {
  const { data } = await axios.post(`/pursue`, {
    ...instance,
    ...pursue
  })
  return data
}

export async function cancelPursue(instance) {
  const { data } = await axios.post(`/pursue/cancel`, {
    instance
  })
  return data
}

export async function getGoogleAuthLink(extension, command) {
  const { data } = await axios.post(`/commands/auth/google`, {
    id: command,
    extension_id: extension
  })
  return data
}

export async function getGoogleContacts(token, pageToken = '') {
  const request = await axios.create({
    headers: { 'Authorization' :`Bearer ${ token }` },
    method: 'get'
  })
  let googleContactsUrl = 'https://people.googleapis.com/v1/otherContacts?readMask=emailAddresses,names'
  if (pageToken.length > 0) {
    googleContactsUrl += `&pageToken=${pageToken}`
  }
  const { data } = await request(googleContactsUrl)
  return data
}

export async function getAutomationCredentials(extension, command) {
  const { data } = await axios.get(`/commands/credentials?extension_id=${extension}&command_id=${command}`)
  return data
}

export async function getPursueInstance(taskId, pageSize = 50, sortOrder = 'asc') {
  const { data } = await axios.get(`/instances?filter[task]=${taskId}&page[size]=${pageSize}&sort[order]=${sortOrder}`)
  return data
}

// refreshes the token
export async function refreshToken(extension, command) {
  const { data } = await axios.get(`/commands/credentials/refresh?extension_id=${extension}&command_id=${command}`)
  return data
}

export async function updatePursue(pursueDetails) {
  const { data } = await axios.put(`/pursue`, {
    ...pursueDetails
  })
  console.log('UPDATE : ', data)
  return data
}

// bring this back if we have to seperately create pursue instances again
// export async function createPursueInstance(instanceData) {
//   const { data } = await axios.post(`/instances`, instanceData)
//   return data
// }
