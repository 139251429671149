import { createSlice } from '@reduxjs/toolkit'

import { FILTER_VALUES } from '../../types/taskFilters.types'

const taskFilterSlice = createSlice({
  name: 'taskFilter',
  initialState: {
    tasks: FILTER_VALUES.initialValue
  },
  reducers: {
    updateFilter: (state, action) => {
      state.tasks = action.payload
    }
  }
})

export const { updateFilter } = taskFilterSlice.actions

export default taskFilterSlice.reducer