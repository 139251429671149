import { combineReducers } from 'redux'

import { loginSuccess, registerSuccess } from './servant/Servant.reducer'
import AutomationReducer from './automations/Automations.reducer'
import { createTaskFilterReducer } from './tasks/TaskFilter.reducer'
import FilterReducer from './filters/Filter.reducer'
// import FriendReducer from './friends/Friend.reducer'
import { FILTER_VALUES } from '../types/taskFilters.types'
import PursueTaskReducer from './pursue/Pursue.reducer'
import ServantReducer from './servant/Servant.reducer'
import WinstonReducer from './winston/Winston.reducer'
import TaskReducer from './tasks/Task.reducer'

const rootReducer = (state, action) => {
  if (action.type === loginSuccess || action.type === registerSuccess) {
		return appReducer({}, action)
  }

  return appReducer(state, action)
}

const appReducer = combineReducers({
	automations: AutomationReducer,
	filters: FilterReducer,
	// friends: FriendReducer,
	servant: ServantReducer,
	pursue: PursueTaskReducer,
	tasks: TaskReducer,
	tasksFilteredByAll: createTaskFilterReducer(FILTER_VALUES.all),
	tasksFilteredByComplete: createTaskFilterReducer(FILTER_VALUES.complete),
	tasksFilteredByIncomplete: createTaskFilterReducer(FILTER_VALUES.incomplete),
	tasksFilteredByOverdue: createTaskFilterReducer(FILTER_VALUES.overdue),
	winston: WinstonReducer
})

export default rootReducer