import { createAction } from '@reduxjs/toolkit'

export const clearDialog = 'winston/clearDialog'
export const clearDialogAction = createAction(clearDialog)

export const createTask = 'winston/createTask'
export const createTaskAction = createAction(createTask)

export const pointAtSignIn = 'winston/pointAtSignIn'
export const pointAtSignInAction = createAction(pointAtSignIn)

export const signInToAddPursue = 'winston/signInToAddPursue'
export const signInToAddPursueAction = createAction(signInToAddPursue)

export const wakeFromIdle = 'winston/wakeFromIdle'
export const wakeFromIdleAction = createAction(wakeFromIdle)

export const welcomeMessage = 'winston/welcomeMessage'
export const welcomeMessageAction = createAction(welcomeMessage)

const actions = {
  [clearDialog]: clearDialogAction(),
  [createTask]: createTaskAction(),
  [pointAtSignIn]: pointAtSignInAction(),
  [signInToAddPursue]: signInToAddPursueAction(),
  [wakeFromIdle]: wakeFromIdleAction(),
  [welcomeMessage]: welcomeMessageAction()
}

export default function WinstonReducer (state = '', action) {
  if (actions[action.type]) {
    return action.type;
  }
  else {
    return state;
  }
};