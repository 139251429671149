import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { Checkbox, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { welcomeMessageAction } from '../../../store/winston/Winston.reducer'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './TaskIndex.css';

import { dropdownOptions} from '../TaskFilters/taskFilters';
import { FILTER_VALUES } from '../../../types/taskFilters.types';
import { FilterSelectors, TaskSelectors } from '../../../store/selectors';
import { getGoogleContacts } from '../../../store/pursue/Pursue.reducer'
import { claimTask, createTask, getTasks, updateTask } from '../../../store/tasks/Task.reducer';
import { finishedLoading, isLoading } from '../../../store/utils';

import { updateFilter } from '../../../store/filters/Filter.reducer'

class TaskIndex extends Component {
  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();

    this.state = {
      selected: [],
      task: '',
    };

    this.getTasks = this.getTasks.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.handleScroll = _.throttle(this.handleScroll.bind(this), 5000, { trailing: false })
    this.toggleStatus = this.toggleStatus.bind(this);
    this.viewTask = this.viewTask.bind(this);
  }

  componentDidMount() {
    this.props.getAccessToken({ extension: 's', command: 'reminder' })
    this.props.winstonWelcome()
    this.getTasks();

    if (this.props.location.search.length) {
      let searchSplit = this.props.location.search.slice(1)
      searchSplit = searchSplit.split('&')

      let hasTask = false
      let hasToken = false
      searchSplit.forEach(str => {
        if (str.includes('task')) {
          hasTask = true
        }
        if (str.includes('token')) {
          hasToken = true
        }
      })
      if (hasTask && hasToken) {
        const claimData = {}
        searchSplit.forEach(id => {
          const claimSplit = id.split('=')
          claimData[claimSplit[0]] = claimSplit[1]
        })
        this.props.claimSharedTask(claimData)
        const { complete } = FILTER_VALUES
        if (claimData.status === complete) {
          this.props.updateTaskFilter(complete)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      finishedLoading(prevProps.createTaskStatus, this.props.createTaskStatus) ||
      finishedLoading(prevProps.claimTaskStatus, this.props.claimTaskStatus)
    ) {
      this.setState({ task: '' });
      this.props.history.push(`/task/${this.props.recentlyCreatedTask.id}`);
    }

    if (prevProps.filterValue !== this.props.filterValue) {
      this.getTasks();
    }
  }

  createTask() {
    const task = {
      due_date: moment({}).startOf('day').unix(),
      task: this.state.task,
      notes: '',
      status: FILTER_VALUES.incomplete
    }
    this.props.createTask({ option: FILTER_VALUES.incomplete, task });
  }

  getTaskFilter() {
    switch(this.props.filterValue) {
      case FILTER_VALUES.overdue:
        const today = moment({}).startOf('day').unix();
        return {
          due_date: { lt: today },
          status: FILTER_VALUES.incomplete
        };

      case FILTER_VALUES.all:
        return {
          status: FILTER_VALUES.all
        };

      default:
        return { status: this.props.filterValue };
    }
  }

  getTasks() {
    const params = { limit: 10, filter: this.getTaskFilter() }
    const { nextToken } = this.props
    if (!!nextToken && nextToken?.length > 0) {
      params.after = nextToken;
    }

    this.props.getTasks({ options: this.props.filterValue, params });
  }

  handleDropdown(e, t) {
    this.props.updateTaskFilter(t.value);
  }

  handleInput(e) {
    this.setState({ task: e.target.value });
  }

  keyPress(e){
    if (e.keyCode === 13){
      this.createTask();
    }
  }

  handleScroll() {
    const { nextToken } = this.props
    const doScroll = () => {
      const fetchingTasks = isLoading(this.props.getTasksStatus)
      const { taskError } = this.props;
      const hasMore = nextToken && nextToken.length > 0;

      // console.log(taskError, fetchingTasks)

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (taskError || fetchingTasks || !hasMore) return;

      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop
        === document.documentElement.offsetHeight
      ) {
        this.getTasks();
      }
    }
    doScroll()
  }

  toggleStatus(task) {
    const newTask = { ...task };
    if (newTask.status === FILTER_VALUES.complete) {
      newTask.status = FILTER_VALUES.incomplete;
    }
    else if (newTask.status === FILTER_VALUES.incomplete) {
      newTask.status = FILTER_VALUES.complete;
    }

    this.props.updateTask(newTask, task);
  }

  viewTask(task) {
    this.props.history.push(`/task/${task.id}`)
  }

  
  render() {
    const { location, tasks } = this.props;

    let currentTaskId = null;
    const taskId = location.pathname.split('/')[2];

    if (taskId) {
      currentTaskId = taskId;
    }

    return (
      <div className="tasks-index-container">

        <div className="tasks-inputs-container">
          <input
            className="create-input"
            onChange={this.handleInput}
            onKeyDown={this.keyPress}
            placeholder='enter a new task...'
            value={this.state.task}
          />

          <Dropdown
            className="s-dropdown"
            fluid
            onChange={this.handleDropdown}
            options={dropdownOptions}
            selection
            value={this.props.filterValue}
          />
        </div>

        <div
          className="tasks-list-container"
          onScroll={this.handleScroll}
          ref={this.scrollContainer}
        >
          {Object.keys(tasks).map((date, key1) => (

            <div className="tasks-group-container" key={key1}>

              <div className="tasks-group-name">
                {date}
                <div className="tasks-group-name-divider"></div>
              </div>

              {tasks[date].incomplete.map((task, key2) => (
                <div
                  className={`
                    task-item
                    ${currentTaskId === task.id && 'highlighted-task'}
                    ${task.status === 'complete' ? 'complete' : 'incomplete'}
                  `}
                  key={key2}
                >
                  <div className="task-checkbox">
                    <Checkbox
                      className="s-checkbox"
                      checked={task.status === FILTER_VALUES.complete}
                      onChange={() => this.toggleStatus(task)}
                    />
                  </div>
                  <div
                    className='task-name'
                    onClick={() => this.viewTask(task)}
                  >
                    {task.task}
                  </div>
                </div>
              ))}

              {tasks[date].complete.map((task, key2) => (
                <div
                className={`
                  task-item
                  ${currentTaskId === task.id && 'highlighted-task'}
                  ${task.status === 'complete' ? 'complete' : 'incomplete'}
                `}
                key={key2}
                >
                  <div className='task-checkbox'>
                    <Checkbox
                      className="s-checkbox"
                      checked={task.status === FILTER_VALUES.complete}
                      onChange={() => this.toggleStatus(task)}
                    />
                  </div>
                  <div
                    className='task-name'
                    onClick={() => this.viewTask(task)}
                  >
                    {task.task}
                  </div>
                </div>
              ))}

            </div>
          ))}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    claimSharedTask: claimTask,
    createTask: createTask,
    getAccessToken: getGoogleContacts,
    getTasks: getTasks,
    // queryUser: getUserByEmail,
    updateTask: updateTask,
    updateTaskFilter: updateFilter,
    winstonWelcome: welcomeMessageAction
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    claimTaskStatus: TaskSelectors.claimTaskStatus(state),
    createTaskStatus: TaskSelectors.createTaskStatus(state),
    filterValue: FilterSelectors.taskFilter(state),
    getTasksStatus: TaskSelectors.getTasksStatus(state),
    nextToken: TaskSelectors.getNextToken(state),
    recentlyCreatedTask: TaskSelectors.recentlyCreatedTask(state),
    tasks: TaskSelectors.getTasksByDate(state),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskIndex));
