/*
* Scene - Awake
*/

import Event from '../'
import animations from '../animations'

const appearAsleep = animations.animations.appearSleep.frames;
const still = animations.animations.still.frames;

const dialogues = [
  `Oh, I didn't see you there.`,
  `Naps keep me quick on my feet!`,
  `No day is so hard it can't be fixed with a nap.`,
  `For naps are what life is made of!`,
  `The nap is mightier than the sword.`,
  `It's better to have napped and lost, than to have never napped at all.`,
]


const AwakeFromIdleEvent = new Event();

AwakeFromIdleEvent.act({
  frames: appearAsleep,
  speak: '',
  duration: 4500,
  loop: true
});
AwakeFromIdleEvent.act({
  frames: still,
  speak: dialogues[Math.floor(Math.random() * dialogues.length)],
  duration: 5000,
});

export default AwakeFromIdleEvent;

// export default (clear) => {

//   position = winston.state.context.position



//   return winston.act({
//     animate: 'appearSleep',
//     duration: 4000,
//   }, clear)
//   .then(() => {

//     // IF Winston is in the side view, skip below
//     if (position === 'side') return

//     // IF Winston is in the center view, talk
//     return winston.act({
//       speak: dialogues[Math.floor(Math.random() * dialogues.length)],
//       duration: 4000,
//     })
//     .then(() => winston.act('help'))
//   })
// }
