class Event {
  constructor() {
    this.event = [];
  }

  act({ frames, duration, loop, speak, speakDelay }) {
    const segment = {
      segments: frames,
      forceFlag: true
    }
    this.event = [ ...this.event, { duration, loop, segment, speak, speakDelay } ];
  }
}

export default Event;