import React from 'react';
import lottie from 'lottie-web';

export default class Lottie extends React.Component {
  constructor(props) {
    super(props)

    this.winstonEl = React.createRef();
  }

  componentDidMount() {
    const {
      options,
      playSegments,
      goToAndPlay,
      goToAndStop,
      eventListeners,
    } = this.props;

    const {
      loop,
      autoplay,
      animationData,
      rendererSettings,
    } = options;

    this.options = {
      container: this.winstonEl.current,
      renderer: 'svg',
      loop: loop !== false,
      autoplay: autoplay !== false,
      playSegments: playSegments?.segments !== null,
      goToAndPlay: goToAndPlay?.value !== null,
      goToAndStop: goToAndStop?.value !== null,
      animationData,
      rendererSettings,
    };

    this.options = { ...this.options, ...options };
    this.anim = lottie.loadAnimation(this.options);

    // Immediately handle goTo's if necessary.
    if (this.options.goToAndPlay) {
      this.goToAndPlay();
    } 
    if (this.options.goToAndStop) {
      this.goToAndStop();
    }
    if (this.options.playSegments) {
      this.playSegments();
    }
    this.registerEvents(eventListeners);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options.animationData !== this.props.options.animationData) {
      this.handleNewData()
    } else if (prevProps.isStopped !== this.props.isStopped) {
      this.stop();
    } else if (prevProps.isPaused !== this.props.isPaused) {
      this.pause();
    } else if (this.props.playSegments) {
      this.playSegments();
    } 

    if (JSON.stringify(this.props.goToAndPlay) !== JSON.stringify(prevProps.goToAndPlay)) {
      this.goToAndPlay();
    }

    if (JSON.stringify(this.props.goToAndStop) !== JSON.stringify(prevProps.goToAndStop)) {
      this.goToAndStop();
    }
  }

  componentWillUnmount() {
    this.deRegisterEvents(this.props.eventListeners);
    this.destroy();
    this.options.animationData = null;
    this.anim = null;
  }

  deRegisterEvents(eventListeners = []) {
    eventListeners.forEach((eventListener) => {
      this.anim.removeEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  destroy() {
    this.anim.destroy();
  }

  goToAndPlay() {
    const { value, isFrame } = this.props.goToAndPlay;
    this.anim.goToAndPlay(value, isFrame);
  }

  goToAndStop() {
    const { value, isFrame } = this.props.goToAndStop;
    this.anim.goToAndStop(value, isFrame);
  }

  handleClickToPause = () => {
    // The pause() method is for handling pausing by passing a prop isPaused
    // This method is for handling the ability to pause by clicking on the animation
    if (this.anim.isPaused) {
      this.anim.play();
    } else {
      this.anim.pause();
    }
  }

  handleNewData() {
    this.deRegisterEvents(this.props.eventListeners);
    this.destroy();
    this.options = { ...this.options, ...this.props.options };
    this.anim = lottie.loadAnimation(this.options);
    this.registerEvents(this.props.eventListeners);
  }

  pause() {
    if (this.anim.isPaused) {
      this.anim.play();
    } else {
      this.anim.pause();
    }
  }

  play() {
    this.anim.play();
  }

  playSegments() {
    const { segments, forceFlag } = this.props.playSegments;
    this.anim.playSegments(segments, forceFlag);
  }

  registerEvents(eventListeners = []) {
    eventListeners.forEach((eventListener) => {
      this.anim.addEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  setSpeed() {
    this.anim.setSpeed(this.props.speed);
  }

  setDirection() {
    this.anim.setDirection(this.props.direction);
  }

  stop() {
    if (this.props.isStopped) {
      this.anim.stop();
    } else {
      this.anim.play();
    }
  }

  render() {
    const {
      width,
      height,
      ariaRole,
      ariaLabel,
      isClickToPauseDisabled,
      title,
    } = this.props;

    const getSize = (initial) => {
      let size;

      if (typeof initial === 'number') {
        size = `${initial}px`;
      } else {
        size = initial || '100%';
      }

      return size;
    };

    const lottieStyles = {
      width: getSize(width),
      height: getSize(height),
      overflow: 'hidden',
      margin: '0 auto',
      outline: 'none',
      ...this.props.style,
    };

    const onClickHandler = isClickToPauseDisabled ? () => null : this.handleClickToPause;

    return (
      <div
        aria-label={ariaLabel}
        onClick={onClickHandler}
        ref={this.winstonEl}
        role={ariaRole}
        style={lottieStyles}
        title={title}
        tabIndex="0"
      />
    );
  }
}