import Event from '../'
import animations from '../animations'

const appearInLeaves = animations.animations.appearLeaves.frames;
const wave = animations.animations.wave.frames;

const WelcomeEvent = new Event();

WelcomeEvent.act({
  frames: appearInLeaves,
  speak: '',
  duration: 4000
});
WelcomeEvent.act({
  frames: wave,
  speak: 'Hello',
  duration: 3000
})

export default WelcomeEvent;