import moment from 'moment';
import ReactGA from 'react-ga';

export async function updateGA (servant) {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-131443274-1', {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: servant.state.user
      }
    })
    // Track new user
    const firstVisit = moment.unix(servant.state.time_created)
    const newUser = moment().diff(firstVisit, "seconds") < 20
    if (newUser) {
      ReactGA.event({
        category: 'UX',
        action: 'New User',
        nonInteraction: true
      });
    }
  }
}