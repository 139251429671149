import React from 'react'

function Logs({ logs }) {
  return (
    <div>
      {logs.map(log => <div key={log.id}>{log.message}</div>)}
    </div>
  )
}

export default Logs