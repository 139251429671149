/* eslint-disable */

/*
* Scene - Help
*/

// import moment from 'moment'
// import servant from '../../servant'
// import servantSDK from '../../sdk'
// import winston from '../'
import Event from '../'
import animations from '../animations'

const still = animations.animations.still.frames

// // Defaults
// let position
// let view
// let loggedIn
// let timeCreated
// let timeRegistered
// let timeLastLogin
// let timeLastVisited

// // Scene
// export default () => {

//   position = winston.state.context.position
//   view = servant.view
//   loggedIn = servant.loggedIn
//   timeCreated = servant.getState().time_created
//   timeRegistered = servant.getState().time_registered
//   timeLastLogin = servant.getState().time_last_login
//   timeLastVisited = servant.getState().user.time_last_visit

//   if (servant.view === 'home') {
//     return winston
//     .act({
//       speak: 'Enter a <b>task</b> in the bar below...',
//       duration: 6000,
//     })
//     .then(() => winston.act({
//       animate: 'pointDown',
//       speak: 'Enter a <b>task</b> in the bar below...',
//       duration: 8000,
//     }))
//   }

//   // Otherwise...
//   return winston.act({
//     speak: `I'm afraid I can't do much to help you here...`,
//     duration: 5000,
//   })
// }


export const userMustSignInForAction = new Event();

userMustSignInForAction.act({
  frames: still,
  speak: 'You must be signed in to create a pursue action',
  duration: 4500,
  loop: false
});