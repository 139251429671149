import { FILTER_VALUES } from '../../../types/taskFilters.types';

export const initialValue = 'incomplete';

export const dropdownOptions = [
  { key: 'incomplete', text: 'incomplete', value: FILTER_VALUES.incomplete },
  { key: 'complete', text: 'complete', value: FILTER_VALUES.complete },
  { key: 'all', text: 'all', value: FILTER_VALUES.all },
  { key: 'overdue', text: 'overdue', value: FILTER_VALUES.overdue },
  // { key: 'recently_completed', text: 'recently completed', value: 'recently_completed' },
  // { key: 'recently_created', text: 'recently created', value: 'recently_created' },
  // { key: 'upcoming', text: 'upcoming', value: 'upcoming' }
];