import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { useLocation } from 'react-router-dom'
import '../Authentication/Authentication.css'
import Logo from '../../assets/servant-logo.png'

import { GoogleButton } from '../Authentication/GoogleAuth'
import { getGoogleAuthLink } from '../../store/pursue/Pursue.reducer'
import { PursueSelectors } from '../../store/selectors'
import Winston from '../Winston/WinstonFull'

function AuthModal(props) {
  const dispatch = useDispatch()
  const params = useParams()

  const googleAuthLink = useSelector(PursueSelectors.getGoogleAuthLink)

  useEffect(() => {
    dispatch(getGoogleAuthLink({ command: 's', extension: 'reminder' }))
  }, [dispatch])

  const claimServant = async(e) => {
    e.preventDefault()
    const pursue = `/task/${params.taskId}/automation/pursue/create`
    localStorage.setItem('AuthForwarding', pursue)
    window.location.href = googleAuthLink
  }

  return (
    <div className="authentication-wrapper">
      <Link to="/">
        <div className="auth-logo">
          <img alt="Servant Logo" src={Logo} draggable={false} />
        </div>
      </Link>
      <div className="auth-header s-red">
        Sign-in for free to enable this automation
      </div>
      <form className="auth-form">
        <Winston />
        <div className="auth-button-wrapper">
          <GoogleButton
            disabled={false}
            handleClick={claimServant}
            text="Continue with Google"
          />
        </div>
      </form>
    </div>
  )
}

export default AuthModal
