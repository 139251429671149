import React from 'react'
import { Icon } from 'semantic-ui-react'

import { GoogleLogin } from 'react-google-login'

const CLIENT_ID = '895876131678-2p9csjhrlhorv9m61p0fv0qa6jv024a6.apps.googleusercontent.com'

export function GoogleButton({ disabled, handleClick, text }) {
  return (
    <button
      className="auth-button"
      disabled={disabled}
      onClick={handleClick}
    >
      <Icon name="google" size="large" />
      {text}
    </button>
  )
}

function GoogleAuth({ handleGoogleAuth, text }) {
  const onSuccess = res => {
    handleGoogleAuth(res.profileObj)
  }

  const onFailure = err => {
    console.log('LOGIN FAILED : ', err)
  }

  return (
    <div>
      <GoogleLogin
        buttonText={text}
        clientId={CLIENT_ID}
        cookiePolicy={'single_host_origin'}
        onFailure={onFailure}
        onSuccess={onSuccess}
        render={renderProps => (
          <GoogleButton
            disabled={renderProps.disabled}
            handleClick={renderProps.onClick}
            text="Continue with Google"
          />
        )}
        style={{ marginTop: '100px' }}
      />
    </div>
  )
}

export default GoogleAuth