import axios from 'axios'
import jwt_decode from 'jwt-decode'

const getServantId = token => {
  const { s_id } = jwt_decode(token)
  return s_id
}

export async function authWithGoogle(profile) {
  const { data } = await axios.post(`/auth/google`, {
    profile
  })
  return data
}

export async function getCredentials(commandId) {
  const { data } = await axios.get(`/commands/credentials?extension_id=s&command_id=${commandId}`)
  return data
}

export async function initExistingUser(token) {
  await initToken(token)
  const servantId = getServantId(token)
  const { data } = await axios.get(`/servants/${servantId}`)
  return data
}

export async function initNewUser() {
  const { data } = await axios.post(`/servants`)
  await initToken(data.token)
  return data
}

export async function initToken(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`

  localStorage.setItem('servant', JSON.stringify({
    loggedIn: true,
    token,
  }))

  return null
}

export async function login(email, password) {
  const { data } = await axios.post(`/user/login`, {
    email: email,
    password: password
  })
  return data
}

export async function register(email, password) {
  const { data } = await axios.post(`/user/register`, {
    email: email,
    password: password
  })
  return data
}

export async function saveCode(code) {
  const { data } = await axios.post('/commands/auth/google/redeem', {
    code,
    id: 'reminder',
    extension_id: 's'
  })
  return data
}

export async function updateUser(user) {
  const { data } = axios.post(`/servant/update`, { user })
  return data
}