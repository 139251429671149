import Event from '../'
import animations from '../animations'

const pointDown = animations.animations.pointDown.frames

const pointAtLoginButton = new Event();

pointAtLoginButton.act({
  frames: pointDown,
  speak: '',
  duration: 2000,
  loop: false
});

export {
  pointAtLoginButton
}