import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment';
import { Icon, Popup } from 'semantic-ui-react'

// import React, { useEffect, useReducer, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'


import './TaskDetails.css';
import IconWinston from '../../../assets/icon-winston.svg';
import IconCalendar from '../../../assets/icon-calendar.svg';
import IconSettings from '../../../assets/icon-settings.svg';

import DatePicker from '../../Common/DatePicker';
import { finishedLoading } from '../../../store/utils';
import { fullDate } from '../../../utils/date';
import { PursueSelectors, ServantSelectors, TaskSelectors } from '../../../store/selectors';
import Logs from './TaskLogs'
import { deleteTask, getTaskById, getLogs, updateTask } from '../../../store/tasks/Task.reducer'
import { getTaskPursueInstance } from '../../../store/pursue/Pursue.reducer'
import Textarea from '../../Common/Textarea';
// import WinstonSide from '../../Winston/WinstonSide'
import { signInToAddPursueAction } from '../../../store/winston/Winston.reducer'

const resetTimeout = (id, newID) => {
	clearTimeout(id)
	return newID
};

// function TaskDetails(props) {
//   const dispatch = useDispatch()
//   const params = useParams()

//   const selectedTask = useSelector(state => TaskSelectors.getTaskById(state, params))

//   const [ date, setDate ] = useState(null)
//   const [ task, setTask ] = useState(selectedTask)
//   const [ timeoutTask, setTimeoutTask ] = useState(null)
//   const [ timeoutDate, setTimemoutDate ] = useState(null)
//   const [ timeoutNotes, setTimeoutNotes ] = useState(null)
//   const [ popoverOpen, setPopoverOpen ] = useState(null)
//   const [ view, setView ] = useState('notes')


//   const [ deleteTaskStatusFromProps, setDeleteTaskStatusFromProps ] = useState(null)

//   const [ taskState, setTaskReducer ] = useReducer(
//     (state, newState) => ({ ...state, ...newState }),
//     { task: {} }
//   )

// useEffect(() => {
//   if (props.task?.id) {
//     dispatch(getTaskPursueInstance({ taskId: props.task.id }))
//     dispatch(getLogs(props.task.id))
//   }
// }, [props.task])

// useEffect(() => {
//   setDeleteTaskStatusFromProps(deleteTaskStatus)
// }, [deleteTaskStatus])



// //   componentDidUpdate(prevProps) {
// //     if (finishedLoading(prevProps.deleteTaskStatus, this.props.deleteTaskStatus)) {
// //       this.props.history.push('/');
// //     }

// //     if (finishedLoading(prevProps.getTasksStatus, this.props.getTasksStatus) && !this.props.task) {
// //       this.props.history.push('/');
// //     }

// //     if (this.props.task && ((!prevProps.task && this.props.task) || (prevProps.task?.id !== this.props.task?.id))) {
// //       this.props.getLogsForTask(this.props.task.id)

// //       const date = this.props.task.due_date;
// //       const dueDate = moment.unix(date, fullDate).startOf('day');
// //       this.setState({
// //         date: dueDate,
// //         task: this.props.task
// //       });
// //     }
// //   }


//   const deleteTask = () => {
//     dispatch(deleteTask(props.task))
//   }

//   const handleDatePicker = date => {
//     const dueDate = moment(date, fullDate).startOf('day')
//     const due_date = moment(date, fullDate).unix()
//     const newTask = { ...task }
//     newTask.due_date = due_date

//     setDate(dueDate)
//     setTask(newTask)
//     setTimemoutDate(resetTimeout(this.state.timeoutDate, null))

//     updateTask()
//   }

//   const handleNotes = notes => {
// 		// Sanitize
// 		if (notes === '<br>') {
//       notes = ''
//     }
// 		if (notes === '&nbsp;') {
//       notes = ''
//     }

//     const newTask = { ...task }
//     newTask.notes = notes

//     handleTask(newTask)
//     setTimeoutNotes(resetTimeout(timeoutNotes, setTimeout(updateTask, 400)))
//   }

//   const handleTask = evt => {
// 		const taskUpdate = evt.target.value
//     const newTask = { ...task }
//     newTask.task = taskUpdate

//     handleTask(newTask)
//     setTimeoutTask(resetTimeout(timeoutTask, setTimeout(updateTask, 400)))
//   }

//   const handleView = view => {
//     setView(view)
//   }

//   const updateTask = () => {
//     const newTask = {
// 			...props.task,
// 			task: task.task,
//       due_date: task.due_date,
//       notes: task.notes,
//     }

//     if (newTask.notes && newTask.notes.length === 0) {
//       newTask.notes = null;
//     }
//     dispatch(updateTask(newTask))
//   }

//   const viewAutomations = () => {
//     if (props.appInit) {
//       props.history.push(`/task/${task.id}/automation`);
//     } else {
//       props.history.push(`/register?r=/task/${task.id}/automation`);
//     }
//   }


//   return (
//     <div>hello</div>
//   )
// }

function setCaretToEnd(target/*: HTMLDivElement*/) {
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(target);
  range.collapse(false);
  sel.removeAllRanges();
  sel.addRange(range);
  target.focus();
  range.detach(); // optimization

  // set scroll to the end if multiline
  target.scrollTop = target.scrollHeight; 
}

// export default TaskDetails
const displayMessageAgain = JSON.parse(localStorage.getItem('winstonMessageTimer')) || moment({}).unix()
const displayWinstonMessage = moment(displayMessageAgain).isSameOrBefore(moment({}).unix())

const resetMessage = () => {
  localStorage.setItem('winstonMessageTimer', JSON.stringify(moment({}).add(1, 'hours').unix()))
}

class TaskDetails extends Component {
  constructor(props) {
    super(props)

    this.createSubtaskInputRef = React.createRef()

    this.state = {
      date: null,
      editSubtask: null,
      popoverOpen: displayWinstonMessage && props.taskPursueInstance?.length === 0,
      popoverDelay: true,
      showSubtaskField: false,
      subtask: '',
      subtasks: [],
      task: props.task,
			timeoutTask: null,
			timeoutDate: null,
      timeoutNotes: null,
      view: 'details'
    }

    this.addSubtask = this.addSubtask.bind(this)
    this.cancelSubtask = this.cancelSubtask.bind(this)
    this.closeSubtaskEdit = this.closeSubtaskEdit.bind(this)
    this.createSubtask = this.createSubtask.bind(this)
    this.createSubTaskEnt = this.createSubTaskEnt.bind(this)
    this.deleteSubtask = this.deleteSubtask.bind(this)
    this.editSubtask = this.editSubtask.bind(this)
    this.handleNewSubtask = this.handleNewSubtask.bind(this)
    this.deleteTask = this.deleteTask.bind(this)
    this.handleDatePicker = this.handleDatePicker.bind(this)
    this.handleEditSubtask = this.handleEditSubtask.bind(this)
    this.handleNotes = this.handleNotes.bind(this)
    this.handleTask = this.handleTask.bind(this)
    this.handleView = this.handleView.bind(this)
    this.updateSubtask = this.updateSubtask.bind(this)
    this.updateTask = this.updateTask.bind(this)
    this.viewAutomations = this.viewAutomations.bind(this)
  }

  componentDidMount() {
    if (!this.props.task) {
      this.props.getTaskById(this.props.match.params.taskId)
    } else {
      this.props.getLogsForTask(this.props.task.id)
      this.props.getTaskPursueInstance({ taskId: this.props.task.id })
      setTimeout(() => {
        this.setState({ popoverDelay: false })
      }, 2500)
    }
  }

  componentDidUpdate(prevProps) {
    if (finishedLoading(prevProps.deleteTaskStatus, this.props.deleteTaskStatus)) {
      this.props.history.push('/');
    }

    if (finishedLoading(prevProps.getTaskByIdStatus, this.props.getTaskByIdStatus) && !this.props.task) {
      this.props.history.push('/');
    }

    if (
      prevProps.task === undefined && this.props.task !== undefined &&
      this.props.task?.subtasks
    ) {
      this.setState({
        subtasks: [ ...this.props.task.subtasks, ...this.state.subtasks ],
        editSubtask: null
      })
    }

    if (this.props.task && prevProps.task?.id !== this.props.match.params.taskId) {
      const subtasks = this.props.task?.subtasks ? this.props.task.subtasks : []
      this.setState({
        subtasks: [ ...subtasks ],
        editSubtask: null
      })
    }

    if (prevProps.taskPursueInstance?.length !== this.props.taskPursueInstance?.length) {
      if (prevProps.taskPursueInstance?.length === 0 && this.props.taskPursueInstance?.length > 0) {
        this.setState({ popoverOpen: false })
      } else {
        this.setState({ popoverOpen: displayWinstonMessage })
      }
    }

    if (this.props.task && ((!prevProps.task && this.props.task) || (prevProps.task?.id !== this.props.task?.id))) {
      this.props.getLogsForTask(this.props.task.id)
      this.props.getTaskPursueInstance({ taskId: this.props.task.id })

      const date = this.props.task.due_date;
      const dueDate = moment.unix(date, fullDate).startOf('day');
      this.setState({
        date: dueDate,
        task: this.props.task
      });
    }
  }

  addSubtask() {
    this.setState({ showSubtaskField: true }, () => {
      this.createSubtaskInputRef.current.focus()
    })
  }

  cancelSubtask() {
    this.setState({
      showSubtaskField: false,
      subtask: ''
    })
  }

  closeSubtaskEdit() {
    this.updateSubtask()
    this.setState({ editSubtask: null })
  }

  createSubtask() {
    const newSubtask = this.createSubTaskEnt()
    const updatedTask = {
      ...this.props.task,
      subtasks: [ ...this.state.subtasks, newSubtask ]
    }
    this.props.updateTask(updatedTask, this.props.task)

    this.setState((prevState, props) => ({
      subtask: '',
      subtasks: [ ...prevState.subtasks, newSubtask ]
    }), () => {
      this.createSubtaskInputRef.current.focus()
    })
  }

  deleteSubtask(index) {
    const subtasks = this.state.subtasks.filter((s, i) => i !== index)
    const updatedTask = {
      ...this.props.task,
      subtasks
    }
    this.props.updateTask(updatedTask, this.props.task)

    this.setState({ subtasks })
  }

  createSubTaskEnt(index) {
    return {
      complete: false,
      created: moment().unix(),
      id: uuidv4(),
      subtask: index ? this.state.subtasks[index].subtask : this.state.subtask,
      taskId: this.props.task.id,
    }
  }

  deleteTask() {
    this.props.deleteTask(this.props.task);
  }

  editSubtask(index) {
    this.setState({ editSubtask: index }, () => {
      this[`subtask-${index}`].focus()
      setCaretToEnd(this[`subtask-${index}`])
    })
  }

  handleDatePicker(date) {
    const dueDate = moment(date, fullDate).startOf('day');
    const due_date = moment(date, fullDate).unix();
    const newTask = { ...this.state.task };
    newTask.due_date = due_date;
    this.setState({
      date: dueDate,
      task: newTask,
      timeoutDate: resetTimeout(this.state.timeoutDate, null)
    }, this.updateTask);
  }

  handleEditSubtask(value, index) {
    const subtasks = cloneDeep(this.state.subtasks)
    subtasks[index].subtask = value
    this.setState({
      subtasks
    })
  }

  handleNewSubtask(value) {
    this.setState({ subtask: value })
  }

  handleNotes(notes) {
		// Sanitize
		if (notes === '<br>') {
      notes = '';
    }
		if (notes === '&nbsp;') {
      notes = '';
    }

    const newTask = { ...this.state.task };
    newTask.notes = notes;
    this.setState({
      task: newTask,
      timeoutNotes: resetTimeout(this.state.timeoutNotes, setTimeout(this.updateTask, 400))
    });
  }

  handleTask(evt) {
		const task = evt.target.value;
    const newTask = { ...this.state.task };
    newTask.task = task;
    this.setState({
      task: newTask,
      timeoutTask: resetTimeout(this.state.timeoutTask, setTimeout(this.updateTask, 400))
    });
  }

  handleView(view) {
    this.setState({ view })
  }

  toggleSubtaskComplete(index) {
    const subtasks = cloneDeep(this.state.subtasks)
    subtasks[index].complete = !subtasks[index].complete
    this.setState({ subtasks })
  }

  updateSubtask() {
    const updatedTask = {
      ...this.props.task,
      subtasks: this.state.subtasks
    }
    this.props.updateTask(updatedTask, this.props.task)
  }

  updateTask() {
    const newTask = {
			...this.props.task,
			task: this.state.task.task,
      due_date: this.state.task.due_date,
      notes: this.state.task.notes,
    };

    if (newTask.notes && newTask.notes.length === 0) {
      newTask.notes = null;
    }
    this.props.updateTask(newTask, this.props.task);
  }

  viewAutomations() {
    this.props.history.push(`/task/${this.state.task.id}/automation/pursue`)
  }

  winstonPopover = () => {
    return (
      <div>
        <div>Want me to pursue someone via email to get this done?</div>
        <div className="winston-popover__to-pursue" onClick={this.viewAutomations}>click here to add pursue</div>
      </div>
    )
  }

  render() {
    if (!this.state.task) {
      return null;
    }

    const automationContent = () => (
      <div className="s-label">
        <img src={IconWinston} className="icon icon-winston" alt="icon" draggable="false" />
        <div className="label-text">automation</div>
      </div>
    )

		const date = this.state.task.due_date
		const dueDate = moment.unix(date, fullDate).startOf('day')

    return (
      <div className="task-details-container">

        {/* <div className="task-details-header-wrapper"> */}
          <input
            className="task-details-header"
            onChange={this.handleTask}
            placeholder='enter your task title...'
            value={this.state.task.task}
          />
          {/* <div className="winston-container"> */}
            {/* <Winston small /> */}
          {/* </div> */}
        {/* </div> */}

        <div className="task-view-container">
          {/* Commment back in when we have logs in order to toggle between logs and details */}
          {/* <div className="task-view-container-header" onClick={() => this.handleView('notes')}>Details</div> */}
          {/* <div className="task-view-container-header" onClick={() => this.handleView('logs')}>Logs</div> */}
        </div>

				<div className="task-details-content">

          {this.state.view === 'logs' &&
            <div className="task-details-content-left">
              <Logs logs={this.props.logs} />
            </div>
          }
          {this.state.view === 'details' &&
            <div className="task-details-content-left">

              {/* This should go in its own component */}
              {/* <div className="task-details-subtask-wrapper">
                <div className="task-details-subheader">Subtasks</div>
                <div className="subtask-list">
                  {this.state.subtasks?.map((subtask, i) => {
                    if (i === this.state.editSubtask) {
                      return (
                        <div className="subtask-item" key={`subtask-${i}-edit`}>
                          <div className="subtask-checkbox">
                            <Checkbox
                              className="s-checkbox"
                              checked={subtask.complete}
                              onChange={() => this.toggleSubtaskComplete(i)}
                            />
                          </div>
                          <Textarea
                            className="add-subtask-input edit-subtask-input"
                            handleChange={this.handleEditSubtask}
                            handleSubmit={this.closeSubtaskEdit}
                            html={subtask.subtask}
                            index={i}
                            keyPressTriggers={['Enter']}
                            onBlur={this.closeSubtaskEdit}
                            ref={input => this[`subtask-${i}`] = input}
                          />
                        </div>
                      )
                    }
                    return (
                      <div
                        className="subtask-list-el"
                        key={`${subtask}-${i}`}
                      >

                        <div className="subtask-item">
                          <div className="subtask-checkbox">
                            <Checkbox
                              className="s-checkbox"
                              checked={subtask.complete}
                              onChange={() => this.toggleSubtaskComplete(i)}
                            />
                          </div>
                          <div
                            className={`subtask-name subtask-nonedit ${subtask.complete ? 'complete' : 'incomplete'}`}
                            onClick={() => this.editSubtask(i)}
                          >
                            {subtask.subtask}
                          </div>
                          <div onClick={() => this.deleteSubtask(i)}>
                            <Icon name="close" />
                          </div>
                        </div>

                      </div>
                    )
                  })}
                </div>

                {!this.state.showSubtaskField && 
                  <div className="s-link-red" onClick={this.addSubtask}>
                    + add subtask
                  </div>
                }

                {this.state.showSubtaskField &&
                  <div>
                    <Textarea
                      className="add-subtask-input"
                      handleChange={this.handleNewSubtask}
                      handleSubmit={this.createSubtask}
                      html={this.state.subtask}
                      keyPressTriggers={['Enter']}
                      label="new-subtask"
                      name="new-subtask"
                      ref={this.createSubtaskInputRef}
                    />
                    <div className="subtask-btn-wrapper">
                      <button className="cancel-btn" onClick={this.cancelSubtask}>Cancel</button>
                      <button className="add-subtask-btn" onClick={this.createSubtask}>Add subtask</button>
                    </div>
                  </div>
                }
              </div> */}
              <div>
                <div className="task-details-subheader">Notes</div>
                <Textarea
                  className="task-details-notes"
                  label="notes"
                  name="notes"
                  html={this.state.task.notes}
                  placeholder="Enter task notes..."
                  handleChange={this.handleNotes}
                />
              </div>
            </div>
          }

					<div className="task-details-content-right">
            {/* <WinstonSide small /> */}
						<div className="task-meta-row">
              <Popup
                className="winston-popover"
                content={this.winstonPopover()}
                open={true}
                // open={!this.state.popoverDelay && this.state.popoverOpen}
                position='left center'
                onClick={() => {
                  resetMessage();
                  this.setState({ popoverOpen: false });
                }}
                trigger={automationContent()}
              />
							{this.props.taskPursueInstance?.length === 0 &&
                <div onClick={this.viewAutomations}>
                  <div className="s-link-grey automation-link">
                    {/* + add automation */}
                    + add pursue
                  </div>
                </div>
              }
              {this.props.taskPursueInstance?.length > 0 &&
                <div className="s-label">
                  <div className="label-text">
                    <Icon name="check" /> pursuit in progress
                  </div>
                </div>
              }
						</div>
						<div className="task-meta-row">
							<div className="s-label">
								<img src={IconCalendar} className="icon icon-calendar" alt="icon" draggable="false" />
								<div className="label-text">due date</div>
							</div>
							<DatePicker
								date={dueDate}
								handleChange={this.handleDatePicker}
                keepOpen={false}
              />
						</div>

						<div className="task-meta-row">
							<div className="s-label">
								<img src={IconSettings} className="icon icon-options" alt="icon" draggable="false" />
								<div className="label-text">options</div>
							</div>
							<div
                className="s-link-grey delete-link"
							  onClick={this.deleteTask}
              >
								delete task
							</div>
						</div>
					</div>
				</div>
      </div>
    );
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getTaskById,
    getLogsForTask: getLogs,
    getTaskPursueInstance,
    deleteTask: deleteTask,
    signInToAddPursue: signInToAddPursueAction,
    updateTask: updateTask,
  }, dispatch);
}

function mapStateToProps(state, props) {
  return {
    appInit: ServantSelectors.appInit(state),
    deleteTaskStatus: TaskSelectors.deleteTaskStatus(state),
    getTaskByIdStatus: TaskSelectors.getTaskByIdStatus(state),
    logs: TaskSelectors.getLogsForTask(state, props),
    task: TaskSelectors.getTaskById(state, props),
    taskPursueInstance: PursueSelectors.getTaskPursueInstance(state),
    updateTaskStatus: TaskSelectors.updateTaskStatus(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
