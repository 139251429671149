import WinstonEvents from '../../winston/scenes';
import { clearDialogAction } from './Winston.reducer'

export const winstonMiddleware = storeAPI => next => action => {
  const { type } = clearDialogAction
  if (!!WinstonEvents[action.type] && action.type !== type) {
    const animationWontLoop = WinstonEvents[action.type].event.every(e => !e.loop)
    if (animationWontLoop) {
      const totalDelay = WinstonEvents[action.type].event
        .map(e => e.duration)
        .reduce((a, b) => a + b)
      
      setTimeout(() => {
        return next({ type })
      }, totalDelay + 2000)
    } else {
      return next(action)
    }
  }
  return next(action)
}