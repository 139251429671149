import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as Api from './Pursue.api'
import { ActionStatus } from '../utils'

export const createPursue = createAsyncThunk(
  'pursue/createPursue',
  async ({ instance, pursue }, thunkAPI) => {
    return await Api.createPursue(instance, pursue)
  }
)

export const cancelPursue = createAsyncThunk(
  'pursue/cancelPursue',
  async ({ id }) => {
    return Api.cancelPursue(id)
  }
)

const getAllContacts = async (data, reject) => {
  const { command, extension, googleToken } = data
  let accessToken = googleToken.access_token
  let contacts = []
  const fetchContacts = async (nextToken = '') => {
    try {
      const { nextPageToken, otherContacts } = await Api.getGoogleContacts(accessToken, nextToken);
      contacts = [ ...contacts, ...otherContacts ]
      if (nextPageToken) {
        fetchContacts(nextPageToken)
      }
    } catch(err) {
      if (err.response?.data.error.code === 401) {
        try {
          const { token } = await Api.refreshToken(extension, command)
          accessToken = token
          fetchContacts()
        } catch(err) {
          return reject({ error: 'Could not get refresh token' })
        }
      }
    }
  }
  await fetchContacts()
  
  return contacts
}

export const getGoogleCredentials = createAsyncThunk(
  'pursue/getGoogleCredentials',
  async ({ extension, command }) => {
    const tokens = await Api.getAutomationCredentials(extension, command)
    const googleToken = tokens.find(token => token.provider === 'google')
    if (googleToken !== undefined) {
      return googleToken.access_token
    }
    return null
  }
)

export const getGoogleContactsActions = createAsyncThunk(
  'pursue/getGoogleContacts',
  async (data, { rejectWithValue }) => {
    const { googleToken } = data
    if (googleToken !== undefined) {
      const token = googleToken.access_token
      const contacts = await getAllContacts(data, rejectWithValue)
      
      return { token, contacts }
    } else {
      return null
    }
  }
)

export const getGoogleAuthLink = createAsyncThunk(
  'pursue/getGoogleAuthLink',
  async (data, { rejectWithValue }) => {
    try {
      const { command, extension } = data
      const { url } = await Api.getGoogleAuthLink(command, extension)
      return url
    } catch(err) {
      return rejectWithValue({ error: 'Could not get google auth link' })
    }
  }
)

export const getTaskPursueInstance = createAsyncThunk(
  'pursue/getTaskPursueInstance',
  async ({ taskId }, { rejectWithValue }) => {
    try {
      // add sortOrder and pageSize later
      // const { pageSize, sortOrder, taskId } = data
      const { data, nextToken } = await Api.getPursueInstance(taskId)
      return { data, nextToken }
    } catch(err) {
      return rejectWithValue({ error: 'Could not get google auth link' })
    }
  }
)

export const updatePursue = createAsyncThunk(
  'pursue/updatePursue',
  async (pursueDetails, thunkAPI) => {
    console.log('i got here???', pursueDetails)
    return await Api.updatePursue(pursueDetails)
  }
)

const pursueSlice = createSlice({
  name: 'pursue',
  initialState: {
    accessToken: '',
    contacts: [],
    googleAuthLink: '',
    instance: null,
    taskPursueInstance: [],
    taskPursueInstanceToken: '',
    
    cancelPursueStatus: ActionStatus.Inactive,
    getPursueStatus: ActionStatus.Inactive,
    googleAuthLinkStatus: ActionStatus.Inactive,
    googleContactsStatus: ActionStatus.Inactive,
    googleCredentialsStatus: ActionStatus.Inactive,
    pursueStatus: ActionStatus.Inactive,
  },
  reducers: {
    getGoogleContactsLoading(state, action) {
      state.googleContactsStatus = ActionStatus.Loading
    },
    saveCodeForPursueSuccessAction(state, action) {
      state.accessToken = action.payload
    },
    saveCodeForPursueFailedAction(state) {
      state.access_token = ''
    },
    setPursueStatusInactiveAction(state) {
      state.pursueStatus = ActionStatus.Inactive
      state.taskPursueInstance = null
    }
  },
  extraReducers: {
    [createPursue.fulfilled]: (state, action) => {
      state.pursueStatus = ActionStatus.Complete
      state.taskPursueInstance = [{ ...action.payload.instance }]
      state.taskPursueInstanceToken = null
    },
    [createPursue.pending]: state => {
      state.pursueStatus = ActionStatus.Loading
    },
    [cancelPursue.fulfilled]: (state, action) => {
      state.cancelPursueStatus = ActionStatus.Complete
    },
    [cancelPursue.pending]: state => {
      state.cancelPursueStatus = ActionStatus.Loading
    },
    [getGoogleAuthLink.fulfilled]: (state, action) => {
      state.googleAuthLinkStatus = ActionStatus.Complete
      state.googleAuthLink = action.payload
    },
    [getGoogleAuthLink.pending]: state => {
      state.googleAuthLinkStatus = ActionStatus.Loading
    },
    [getGoogleContactsActions.fulfilled]: (state, action) => {
      if (action.payload) {
        state.contacts = action.payload?.contacts
        state.googleContactsStatus = ActionStatus.Complete
      }
    },
    [getGoogleCredentials.pending]: state => {
      state.googleCredentialsStatus = ActionStatus.Loading
    },
    [getGoogleCredentials.fulfilled]: (state, action) => {
      state.googleCredentialsStatus = ActionStatus.Complete
      state.accessToken = action.payload
    },
    [getTaskPursueInstance.pending]: (state) => {
      state.getPursueStatus = ActionStatus.Loading
    },
    [getTaskPursueInstance.fulfilled]: (state, action) => {
      state.getPursueStatus = ActionStatus.Complete
      state.taskPursueInstance = action.payload.data
      state.taskPursueInstanceToken = action.payload.nextToken
    },
    [updatePursue.fulfilled]: (state, action) => {
      state.pursueStatus = ActionStatus.Complete
      state.taskPursueInstance = [{ ...action.payload.instance }]
      state.taskPursueInstanceToken = null
    },
    [updatePursue.pending]: state => {
      state.pursueStatus = ActionStatus.Loading
    }
  }
})

export const {
  getGoogleContactsLoading,
  removeStalePursueInstanceAction,
  saveCodeForPursueSuccessAction,
  saveCodeForPursueFailedAction,
  setPursueStatusInactiveAction
} = pursueSlice.actions

export const getGoogleContacts = ({ command, extension }) => async dispatch => {
  dispatch(getGoogleContactsLoading())
  try {
    const tokens = await Api.getAutomationCredentials(extension, command)
    const googleToken = tokens.find(token => token.provider === 'google')
    if (googleToken !== undefined) {
      dispatch(getGoogleContactsActions({ extension, command, googleToken }))
      dispatch(saveCodeForPursueSuccessAction(googleToken.access_token))
    }
  } catch (err) {
    dispatch(saveCodeForPursueFailedAction())
    console.log('ERROR : ', err)
  }
}

export default pursueSlice.reducer



// bring this back if we have to seperately create pursue instances again
// export const createPursueInstance = createAsyncThunk(
//   'pursue/createPursueInstance',
//   async ({ command, extension, task }, thunkAPI) => {
//     const instanceData = { command, extension, task }
//     return await Api.createPursueInstance(instanceData)
//   }
// )