import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { isComplete, isInactive } from '../../../../store/utils'
import { createPursue } from '../../../../store/pursue/Pursue.reducer'
import { getPursueStatus, getPursueInstance, getPursueInstanceStatus } from '../../../../store/pursue/Pursue.selectors'

function CreatePursue (props){
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  // const pursueInstanceStatus = useSelector(getPursueInstanceStatus)
  const pursueInstanceData = useSelector(getPursueInstance)
  const pursueStatus = useSelector(getPursueStatus)
  const pursueInstanceStatus = useSelector(getPursueInstanceStatus)

  useEffect(() => {
    const savedPursueData = JSON.parse(localStorage.getItem('ServantAutomation'))
    // POST pursue instance
    if (pursueInstanceData === null && isInactive(pursueInstanceStatus)) {
      const instance = savedPursueData.pursueInstance
      const pursue = savedPursueData.pursue
      dispatch(createPursue({ instance, pursue }))
    }
  }, [dispatch, pursueInstanceData, pursueInstanceStatus])

  // bring this back if we have to seperately create pursue instances again
  // useEffect(() => {
  //   // POST pursue after instance created
  //   if (pursueInstanceData?.id) {
  //     const savedPursueData = JSON.parse(localStorage.getItem('ServantAutomation'))
  //     const pursue = savedPursueData.pursue
  //     pursue.instance = pursueInstanceData.id
  //     dispatch(createPursue(pursue))
  //   }
  // }, [dispatch, pursueInstanceData])

  
  useEffect(() => {
    // Alert user pursue was created
    if (isComplete(pursueStatus)) {
      history.push(`/task/${params.taskId}`)
    }
  }, [history, params, pursueStatus])

  return null
}

export default CreatePursue