import React, { useEffect, useState } from 'react'
import {  Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { authWithGoogle } from '../../store/servant/Servant.reducer'
import { clearDialogAction, pointAtSignInAction } from '../../store/winston/Winston.reducer'
import LegalFooter from '../Legal/LegalFooter'
import GoogleAuth from './GoogleAuth'
import Logo from '../../assets/servant-logo.png';
import { ServantSelectors } from '../../store/selectors';
import Winston from '../Winston/WinstonFull'
import './Authentication.css'

function AuthModal() {
  const dispatch = useDispatch()
  const history = useHistory()
  // let winstonWaveRef = useRef(null)

  const isGuest = useSelector(ServantSelectors.isGuest)

  const [ userLoggingIn, setUserLoggingIn ] = useState(false)
  const [ fade, setFade ] = useState(false)

  useEffect(() => {
    if (isGuest !== true) {
      history.push('/')
    }
  }, [history, isGuest])

  useEffect(() => {
    dispatch(pointAtSignInAction())
    const winstonWave = setInterval(() => {
      dispatch(pointAtSignInAction())
    }, 7000)
    // winstonWaveRef.current = winstonWave

    return () => {
      dispatch(clearDialogAction())
      clearInterval(winstonWave)
    }
  }, [dispatch])

  const handleGoogleAuth = profile => {
    dispatch(authWithGoogle({ profile }))
    setFade(true)
    setTimeout(() => {
      setUserLoggingIn(true)
    }, 900)
  }

  const fadeOut = fade ? 'fadeOut' : ''

  return (
    <div className="authentication-wrapper">
      <Link to="/">
        <div className="auth-logo">
          <img alt="Servant Logo" src={Logo} draggable={false} />
        </div>
      </Link>
      <div className={`auth-header ${fadeOut}`}>
        {!userLoggingIn && "log in to your servant"}
      </div>
      <Winston />
      <div className={`auth-button-wrapper ${fadeOut}`}>
        {!userLoggingIn &&
          <GoogleAuth
            handleGoogleAuth={handleGoogleAuth}
            text={'Login with Google'}
          />
        }
      </div>
      <div className="legal-container">
        <LegalFooter />
      </div>
    </div>
  )
}

export default AuthModal
