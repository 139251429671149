import moment from 'moment';

export const sortTasksByDate = tasks => {
  return tasks.sort((a,b) => {
    return a.due_date - b.due_date;
  });
};

export const tasksByDate = tasks => {
  const taskObject = {}

  tasks.forEach(t => {
    const date = moment.unix(t.due_date)
    const backlogDate = moment({}).subtract(1, 'days').endOf('day')

    // Check if is overdue (before current date and incomplete)
    if (moment(date).isBefore(backlogDate)) {
      // if (t.status === 'complete') return
      if (!taskObject.backlog) {
        taskObject.backlog = { incomplete: [], complete: [] }
      }
      taskObject.backlog.incomplete.push(t)
      return
    }

    // Otherwise, create key for its upcoming day
    let key = date.format('dddd - LL').toLowerCase()

    if (!taskObject[key]) {
      taskObject[key] = {
        incomplete: [],
        complete: [],
      }
    }
    const status = t.status || 'incomplete';
    taskObject[key][status].push(t)
  })

  // Sort tasks alphabetically
  for (const key in taskObject) {
    taskObject[key].incomplete.sort(function (a, b) {
      if (a.task < b.task) return -1;
      if (a.task > b.task) return 1;
      return 0;
    })
    taskObject[key].complete.sort(function (a, b) {
      if (a.task < b.task) return -1;
      if (a.task > b.task) return 1;
      return 0;
    })
  }

  return taskObject;
}