import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import animations from '../../winston/animations';
import { connect } from 'react-redux';
import Lottie from '../Lottie/Lottie';
import { WinstonSelectors } from '../../store/selectors';
import { clearDialogAction, wakeFromIdle, wakeFromIdleAction } from '../../store/winston/Winston.reducer';
import WinstonEvents from '../../winston/scenes'
import './Winston.css';
import { getVisibility } from './utils';

const stand = animations.animations.still.frames;

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animations.data,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

class Winston extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStopped: false,
      isPaused: false,
      options: defaultOptions,
      segment: {
        segments: stand,
        forceFlag: true
      },
      speak: ''
    };
  }

  componentDidMount() {
    // event listener to check if user tabbed off
    document.addEventListener('visibilitychange', this.idleWinston);
  }

  componentDidUpdate(prevProps) {
    // if a new event is scheduled, play it immediately
    if (prevProps.event !== this.props.event) {
      const scenes = WinstonEvents[this.props.event].event
      if (scenes[0]) {
        // cancel out previous animation and play new one
        clearTimeout(this.playEventTimer)
        this.playEvents(scenes, 0)
      }
    }
  }

  componentWillUnmount() {
    // remove event listener on unmount
    document.removeEventListener('visibilitychange', this.idleWinston);
  }

  idleWinston = () => {
    const hidden = getVisibility()
    const userIdle = document[hidden]
    if (userIdle) {
      this.idleTimeout = setTimeout(() => {
        this.props.clearDialog()
        this.setState({ winstonSleep: true })
      }, 10000)
    }
    else if (!userIdle && this.state.winstonSleep === true) {
      this.props.wakeFromIdle()
      this.setState({ winstonSleep: false })

      setTimeout(() => {
        if (this.props.event === wakeFromIdle) {
          this.props.clearDialog()
        }
      }, 5000)
    } else {
      if (this.idleTimeout) {
        clearTimeout(this.idleTimeout)
      }
    }
  }

  playEvents = (scenes, index) => {
    const scene = scenes[index]; 
    this.setState({
      segment: scene.segment,
      speak: scene.speak
    }, () => {
      this.playEventTimer = setTimeout(() => {
        if (scenes[index + 1]) {
          this.playEvents(scenes, index + 1);
        }
      }, scene.duration)
    })
  }

  play = () => {
    this.setState({
      isStopped: false
    });
  }

  render() {
    // console.log(1, WinstonEvents[this.props.event]?.event)
    // console.log(2, WinstonEvents[this.props.event]?.event[0])
    // const winstonText = WinstonEvents[this.props.event]?.event[0].speak || ''
    // const isVisible = this.state.speak.length > 0 ? '' : 'hidden'
    const isVisible = this.state.speak.length > 0 ? true : false
    const animationStyle = isVisible ? 'fadeIn' : 'fadeOut'
    return (
      <div id={this.props.small ? 'w-side' : 'w-large'} className="w-container">
        <div id='w-inner'>
          
          {/* <div id='w-dialogue' className='fadeIn fadeOut' style={{ visibility: isVisible }}> */}
          {isVisible && <div id='w-dialogue' className={animationStyle}>
            <div id='w-dialogue-bubble'>{this.state.speak}</div>
            <div id='w-dialogue-arrow'>
              <svg xmlns='http://www.w3.org/2000/svg'
                width='28'
                height='28'
                viewBox='0 0 28 28'>
                <path id='w-dialogue-arrow-path'
                  d='M3 14 L14 25 L26 14' />
              </svg>
            </div>
          </div>
          }
          <Lottie
            autoplay={false}
            options={this.state.options}
            height={200}
            width={this.props.small ? 'auto' : 400}
            loop={this.state.loop}
            playSegments={this.state.segment}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
            goToAndPlay={{ value: null, isFrame: null }}
            goToAndStop={{ value: null, isFrame: null }}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clearDialog: clearDialogAction,
    wakeFromIdle: wakeFromIdleAction,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    event: WinstonSelectors.getEvent(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Winston);