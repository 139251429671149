import React, { Component } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './DatePicker.css'

import { SingleDatePicker  } from 'react-dates'

class DatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
    }
  }

  render() {
    return (
      <SingleDatePicker
        anchorDirection="right"
        date={this.props.date} // momentPropTypes.momentObj or null
        focused={this.state.focused} // PropTypes.bool
        hideKeyboardShortcutsPanel={true}
        id="datepicker" // PropTypes.string.isRequired,
        keepOpenOnDateSelect={this.props.keepOpen}
        noBorder
        numberOfMonths={1} // Number of months to display
        onDateChange={date => this.props.handleChange(date)} // PropTypes.func.isRequired
        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
        openDirection="down"
        placeholder="no due date"
        readOnly={true}
        small={true}
      />
    )
  }
}

export default DatePicker;
