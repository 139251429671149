import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import axios from 'axios';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { winstonMiddleware } from './store/winston/Winston.middleware'

import App from './components/App';
import rootReducer from './store/reducers';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://rgz8v0cuyc.execute-api.us-east-1.amazonaws.com/dev/'
} else {
  axios.defaults.baseURL = 'https://ufypyoebb4.execute-api.us-east-1.amazonaws.com/prod/'
}


// Load tokenData
let tokenData = localStorage.getItem('servant');
tokenData = tokenData ? JSON.parse(tokenData) : null;

// If token is undefined, reset everything
if (tokenData && tokenData.token && tokenData.token.length > 0) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ tokenData.token }`;
}

const middleware = [ ...getDefaultMiddleware(), winstonMiddleware ];

const store = configureStore({
  reducer: rootReducer,
  middleware
})

ReactDOM.render(
  <BrowserRouter>
    <Provider store={ store }>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);