import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Divider, Icon } from 'semantic-ui-react'

// Returns the datetime for the first pursue
// This is needed in case user is making a pursue between midnight and 6:59am
// Otherwise returns tomorrows datetime
export const getStartDate = () => {
  const now = moment({})
  // return now
  const sevenAM = moment({}).hour(7).minute(0).second(0)

  if (now.isBefore(sevenAM)) {
    return sevenAM
  } else {
    return moment({}).add(1, 'days').hour(7).minute(0).second(0)
  }
}

const AutoTextArea = (props) => {
	const textAreaRef = useRef(null);
	const [text, setText] = useState("");
	const [textAreaHeight, setTextAreaHeight] = useState("auto");
	const [parentHeight, setParentHeight] = useState("auto");

	useEffect(() => {
		setParentHeight(`${textAreaRef?.current?.scrollHeight}px`);
		setTextAreaHeight(`${textAreaRef?.current?.scrollHeight}px`);
	}, [text]);

	const onChangeHandler = (event) => {
		setTextAreaHeight("auto");
		setParentHeight(`${textAreaRef?.current?.scrollHeight}px`);
		setText(event.target.value);

		if (props.onChange) {
			props.onChange(event);
		}
	};

	return (
		<div
			style={{
				minHeight: parentHeight,
			}}
		>
			<textarea
				{...props}
				ref={textAreaRef}
				rows={3}
				style={{
					height: textAreaHeight,
				}}
				onChange={onChangeHandler}
			/>
		</div>
	);
};


function PursueCard({ invalid, pursue, pursueIndex, pursues, updatePursue }) {
  const dayOfWeek = pursue.datetime.format('dddd')
  const monthDay = pursue.datetime.format("MMMM Do")
  const maxDay = moment({}).add(14, 'days')

  const clearCard = () => {
    // If only a single card in array, clear data
    if (pursues.length === 1) {
      const data = { datetime: getStartDate(), sequence: 1, subject: '', text: '' }
      updatePursue(data, pursueIndex)
    } else {
      // Otherwise remove card from array
      const newPursues = [ ...pursues ]
      newPursues.splice(pursueIndex, 1)
      newPursues.map((p, i) => ({ ...p, sequence: i }))
      updatePursue(newPursues, null)
    }
  }

  const getYesterday = () => {
    const pursueDate = moment(pursue.datetime)
    const prevDay = pursueDate.subtract(1, 'day').hour(7).minute(0).second(0)
    const prevPursueDate = pursues[pursueIndex - 1]?.datetime
    const afterPrevPursue = !prevPursueDate || prevDay.isAfter(prevPursueDate)
    const latestDate = getStartDate().unix()
    
    // Make sure that the pursue card before this one doesn't share the same datetime (afterPrevPursue)
    // Make sure that they can't start a pursue using yesterday's datetime
    
    if (prevDay.unix() >= latestDate && afterPrevPursue) {
      const data = { ...pursue, datetime: moment(prevDay) }
      updatePursue(data, pursueIndex)
    }
  }

  const getTomorrow = () => {
    const pursueDate = moment(pursue.datetime)
    const tomorrow = pursueDate.add(1, 'day')
    const nextPursueDate = pursues[pursueIndex + 1]?.datetime
    const beforeNextPursue = !nextPursueDate || tomorrow.isBefore(nextPursueDate)

    // Make sure that pursue card after this one doesn't share the same datetime (beforeNextPursue)
    // Make sure that pursue card's datetime doesn't go beyond 2 weeks
    if (tomorrow.isSameOrBefore(maxDay) && beforeNextPursue) {
      const data = { ...pursue, datetime: moment(tomorrow) }
      updatePursue(data, pursueIndex)
    }
  }

  const updateSubject = e => {
    const data = { ...pursue, subject: e.target.value }
    updatePursue(data, pursueIndex)
  }

  const updateText = e => {
    const data = { ...pursue, text: e.target.value }
    updatePursue(data, pursueIndex)
  }
  
  return (
    <div className="pursue-card-wrapper">
      <div className={`pursue-card-container ${invalid ? 'invalid-property' : ''}`}>
        <div className="pursue-card-header">
          <div className="pursue-card-header-left">
            <Icon
              className="card-icons"
              name="angle left"
              onClick={getYesterday}
            />
            <Icon
              className="card-icons"
              name="angle right"
              onClick={getTomorrow}
            />
            <div className="pursue-card-header-text">{dayOfWeek}, {monthDay} at 7am</div>
          </div>
          <div className="pursue-card-header-right">
            <Icon
              className="card-icons"
              name="close"
              onClick={clearCard}
            />
          </div>
        </div>
        <Divider className="pursue-card-divider" />
        <div className="pursue-subject">
          <label className="pursue-card-label">Subject</label>
          <input
            maxLength="60"
            onChange={updateSubject}
            placeholder="Type your subject here.."
            value={pursue.subject}
          />
        </div>
        <div className="pursue-textarea">
          <label className="pursue-card-label">Message</label>
          <AutoTextArea
            placeholder="Type your message here.."
            onChange={updateText}
            value={pursue.text}
          />
        </div>
      </div>
    </div>
  )
}

export default PursueCard