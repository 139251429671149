/*
* Winston Animations
*/

import animationData from './data.js'

const winstonData = {

  data: animationData,

  animations: {
    still: {
      frames: [97, 98],
      seconds: 1,
    },
    appearLeaves: {
      frames: [0, 96],
      seconds: 3,
    },
    wave: {
      frames: [97, 144],
      seconds: 2,
    },
    tapFoot: {
      frames: [145, 192],
      seconds: 2,
    },
    appearSleep: {
      frames: [193, 288],
      seconds: 4,
    },
    pointDown: {
      frames: [289, 336],
      seconds: 2,
    },
    sleep: {
      frames: [193, 277],
      seconds: 2,
    }
  }
}

export default winstonData
