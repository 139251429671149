import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Creatable from 'react-select'
import './PursueModalContainer.css'

import {
  createPursue,
  getTaskPursueInstance,
  setPursueStatusInactiveAction,
  updatePursue as updatePursueAction
} from '../../../../store/pursue/Pursue.reducer'
import { isComplete, isInactive } from '../../../../store/utils'
import Modal from '../../../Common/Modal'
import PursueCard, { getStartDate } from './PursueCard'
import { PursueSelectors, ServantSelectors } from '../../../../store/selectors'

// Makes sure dates for pursues are all valid
// IE: User idles pursue for a days in a tab, the comes back and tries to make a pursue with old datetime that was once valid
const checkPursueDates = pursues => {
  const invalidIndicies = []
  const unixSoonestValidDate = getStartDate().unix()
  pursues.forEach((p, i) => {
    const unixDate = p.datetime.unix()
    if (unixDate < unixSoonestValidDate) {
      invalidIndicies.push(i)
    }
  })
  return []
  // return invalidIndicies
}

// Creates format for sending pursue to server
const createPursueData = (pursues, pursueInstance, recipients, taskId) => {  
  const pursueData = { emails: [] }
  // pursueData.instance = pursueInstance?.id || null
  pursueData.recipients = recipients.map(r => r.value)
  pursueData.task = taskId

  pursues.forEach(p => {
    pursueData.emails.push({
      datetime: p.datetime.unix(),
      message: p.text,
      subject: p.subject
    })
  })

  return pursueData
}

// Format data for contacts dropdown
const createContactOptions = (contacts = []) => {
  if (contacts.length === 0) {
    return []
  }
  return contacts.map(contact => {
    const c = {
      key: null,
      label: '',
      value: null
    }
    if (contact.emailAddresses) {
      c.key = contact.etag
      c.label = contact.emailAddresses[0].value
      c.value = contact.emailAddresses[0].value
    }
    if (contact.names) {
      const name = contact.names[0].displayName
      c.label = `${c.label} (${name})`
    } else {
      c.label = `${c.label} (name not found)`
    }
    return c
  })
}

function PursueModalContainer() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const extension = 's'
  const command = 'pursue'

  const accessToken = useSelector(PursueSelectors.getAccessToken)
  const getPursueStatus = useSelector(PursueSelectors.getFetchPursueStatus)
  const googleContactsFromProps = useSelector(PursueSelectors.getGoogleContacts)
  const isGuest = useSelector(ServantSelectors.isGuest)
  const pursueInstance = useSelector(PursueSelectors.getTaskPursueInstance)
  const pursueStatus = useSelector(PursueSelectors.getPursueStatus)

  const [ pursues, setPursues ] = useState([{
    datetime: getStartDate(),
    subject: '',
    text: ''
  }])
  const [ creatingPursue, setCreatingPursue ] = useState(undefined)
  const [ googleContacts, setGoogleContacts ] = useState([])
  const [ invalidDates, setInvalidDates ] = useState([])
  const [ originalContacts, setOriginalContacts ] = useState([])
  const [ originalPursues, setOriginalPursues ] = useState([])
  const [ selectedContacts, setSelectedContacts ] = useState([])
  const [ selectValue, setSelectValue ] = useState('')
  const [ hasContacts, setHasContacts ] = useState(true)

  // If you comes directly to pursue modal page, get the instance to see if we have one already running
  useEffect(() => {
    if (isInactive(getPursueStatus)) {
      dispatch(getTaskPursueInstance({ taskId: params.taskId }))
    }
    if (isComplete(getPursueStatus) && pursueInstance.length > 0) {
      const { emails, recipients } = pursueInstance[0]

      const formattedRecipients = recipients.map(recipient => ({ label: recipient, value: recipient }))

      const pursueCards = emails.map(({ id, datetime, message, subject }, i) => { 
        const dateString = moment.unix(datetime).format("MM/DD/YYYY");
        const card = { 
          datetime: moment(dateString),
          sequence: i+1,
          subject,
          text: message
        }
        if (id) {
          card.id = id
        }
        return card
      })

      setCreatingPursue(false)
      setOriginalContacts(recipients)
      setOriginalPursues(pursueCards)
      setPursues(pursueCards)
      setSelectedContacts(formattedRecipients)
    } else {
      setCreatingPursue(true)
    }
  }, [dispatch, getPursueStatus, params.taskId, pursueInstance])

  // On load, get contacts and make sure PursueInstanceAction is set to Inactive
  useEffect(() => {
    let ignore = false
    function getContacts() {
      // if (loading(getGoogleContactsStatus) || googleContacts.length === 0) {
      //   dispatch(getGoogleContacts({ command: 'reminder', extension: 's' }))
      // }
      if (ignore) {
        dispatch(setPursueStatusInactiveAction())
      }
    }

    getContacts()
    return () => { ignore = true }
  }, [dispatch, googleContacts])

  // Once a pursue instance and pursue is complete, push them to the task details page
  useEffect(() => {
    if (isComplete(pursueStatus)) {
      dispatch(setPursueStatusInactiveAction())
      history.push(`/task/${params.taskId}`);
    }
  }, [dispatch, history, params.taskId, pursueStatus])

  // Check to see if google gave us contacts
  useEffect(() => {
    if (googleContacts.length === 0 && googleContactsFromProps.length > 0) {
      setGoogleContacts(createContactOptions(googleContactsFromProps))
    }
  }, [dispatch, googleContacts, googleContactsFromProps])

  const addPursueCard = () => {
    if (pursues.length >= 3) {
      return
    }

    const newPursues = [ ...pursues ]
    const lastPursue = newPursues[newPursues.length - 1]
    newPursues.push({
      datetime: moment(lastPursue.datetime).add(1, 'day'),
      sequence: pursues.length+1,
      subject: lastPursue.subject,
      text: lastPursue.text
    })
    setPursues(newPursues)
  }

  const closeModal = () => {
    history.push(`/task/${params.taskId}`)
  }

  const getAddedRemovedContacts = () => {
    const selected = selectedContacts.map(c => c.value)
    const add = selected.filter(contact => originalContacts.indexOf(contact) === -1)
    const remove = originalContacts.filter(contact => selected.indexOf(contact) === -1)
    return { add, remove }
  }

  const getAddedRemovedUpdatedPursues = () => {
    // added
    // when toms stops being an idiot we will get rid of unix
    const add = pursues.filter(p => p.id === undefined).map(p => ({
      ...p,
      datetime: p.datetime.unix(),
      message: p.text
    }))
    
    // removed
    const originalIds = originalPursues.filter(p => p.id !== undefined).map(p => p.id)
    const pursueIds = pursues.filter(p => p.id !== undefined).map(p => p.id)
    const remove = originalIds.filter(id => pursueIds.indexOf(id) === -1)

    // updated
    let update = []
    pursues.forEach(pursue => {
      const original = originalPursues.find(p => p.id === pursue.id)
      if (original) {
        const notSameDate = original.datetime !== pursue.datetime
        const notSameSubject = original.subject !== pursue.subject
        const notSameText = original.text !== pursue.text

        if (notSameDate || notSameSubject || notSameText) {
          update.push(pursue)
        }
      }
    })
    // when toms stops being an idiot we will get rid of unix
    update = update.map(p => ({ ...p, message: p.text, datetime: p.datetime.unix() }))

    return { add, remove, update }
  }

  const handleAdditionForSelect = () => {
    if (selectValue.length > 0) {
      const data = { label: selectValue, value: selectValue  }
      setGoogleContacts([ ...googleContacts, data ])
      setSelectedContacts([ ...selectedContacts, data ])
      setSelectValue('')
    }
  }

  const handleCreatableChange = (data, actions) => {
    switch(actions.action) {
      case 'select-option':
      case 'remove-value':
        setSelectedContacts(data)
        break
      default:
        return
    }
  }

  const handleKeyDown = e => {
    if (selectValue.length > 0) {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
        case ',':
          handleAdditionForSelect()
          e.preventDefault();
          break
        default:
          return
      }
    }
  }

  const submitPursue = () => {
    const hasContacts = selectedContacts.length > 0
    const pursuesWithInvalidDates = checkPursueDates(pursues)
    if (
      pursuesWithInvalidDates.length > 0 ||
      !hasContacts
    ) {
      setInvalidDates(pursuesWithInvalidDates)
      setHasContacts(hasContacts)
    } else {
      const pursueData = createPursueData(pursues, pursueInstance, selectedContacts, params.taskId)
      const instance = { command, extension, task: params.taskId }

      if (!isGuest && accessToken?.length > 0) {
        if (creatingPursue) {
          // If not guest and signed in, create pursue instance
          dispatch(createPursue({ instance, pursue: pursueData }))
        } else {
          // User updating existing pursue
          const addedRemovedContacts = getAddedRemovedContacts()
          const addedRemovedUpdatedPursues = getAddedRemovedUpdatedPursues()
          const instanceId = pursueInstance[0].id

          // console.log('CONTACTS PROPS : ', addedRemovedContacts)
          // console.log('EMAIL PROPS : ', addedRemovedUpdatedPursues)
          // console.log('SEQUENCE : ', sequence)
          // console.log('INSTANCE ID : ', instanceId)

          dispatch(updatePursueAction({
            recipients: {
              ...addedRemovedContacts
            },
            emails: {
              ...addedRemovedUpdatedPursues
            },
            instance: instanceId
          }))
        }


      } else {
        // If they are not signed in, we need to save the data to localStorage
        // After user auth's, FE will get data from localStorage and send to server
        localStorage.setItem('ServantAutomation', JSON.stringify({
          pursue: { ...pursueData },
          pursueInstance: { ...instance }
        }))
        history.push(`/task/${params.taskId}/automation/claim`)
      }
    }
  }

  const updatePursue = (data, i) => {
    if (i === null) {
      setPursues(data)
    } else {
      const newPursues = [ ...pursues ]
      newPursues[i] = data
      setPursues(newPursues)
    }
  }
  
  const noContactsWarning = hasContacts ? '' : 'invalid-property-contacts'

  return (
    <Modal>
      <div className="automation-modal">
        <div className="close-modal-icon">
          <Icon
            className="card-icons close-modal-icon"
            name="close"
            onClick={closeModal}
          />
        </div>
        <div className="automation-container">
          <div className="automation-details">
            <div className="s-label">
              <div className="page-label">Automations</div>
            </div>
            <div className="automation-header">Pursue</div>
            <div>
              <div className="automation-details-text-group">Schedule emails to an individual requesting something from them.</div>
              <div className="automation-details-text-group">The email will be sent through your personal Gmail address, appearing like any other you normally send.</div>
            </div>
          </div>

          <div className="automation-inputs">
            <div className="pursue-recipients">
              <div className="pursue-recipients-input">
                <div className="automation-data-label">Recipient's Email Address</div>

                <Creatable
                  // className="pursue-contacts-dropdown"
                  // defaultValue={selectedContacts}
                  onBlur={handleAdditionForSelect}
                  className={`pursue-contacts-dropdown ${noContactsWarning}`}
                  options={googleContacts}
                  onChange={handleCreatableChange}
                  onInputChange={setSelectValue}
                  isMulti
                  onKeyDown={handleKeyDown}
                  placeholder="Enter an email..."
                  inputValue={selectValue}
                  value={selectedContacts}
                />
                
              </div>
              <div className="pursue-recipients-submit">
                <button
                  className="submit-pursue-button"
                  onClick={submitPursue}
                >
                  { creatingPursue === true ? 'Save & Start' : creatingPursue === false ? 'Update' : '' }
                </button>
              </div>
            </div>
            <div className="pursue-scheduler">
              <div className="automation-data-label">Scheduled Emails</div>
              <div>
                {pursues.map((pursue, i) => (
                  <PursueCard
                    invalid={invalidDates.includes(i)}
                    key={`pursue-card-${i}`}
                    pursue={pursue}
                    pursueIndex={i}
                    pursues={pursues}
                    updatePursue={updatePursue}
                  />
                ))}
                <div className="pursue-card-wrapper">
                  <div
                    className="pursue-add-message"
                    onClick={addPursueCard}
                  >
                    Add a scheduled message
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PursueModalContainer