import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './Sidebar.css';
import Logo from '../../assets/servant-logo.png';

import { logout } from '../../store/servant/Servant.reducer';
import { ServantSelectors } from '../../store/selectors';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.logout();
    window.location.reload();
  }


  render() {
    const { isGuest } = this.props;
    const { pathname } = this.props.location;

    return (
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to='/'>
            <img alt="Servant Logo" className="logo" src={Logo} draggable={false} />
          </Link>
        </div>
        <ul className="sidebar-nav-options">
          <li className={pathname === '/' || pathname.indexOf('task') > -1 ? 'selected-option' : ''}>
            <Link to='/'>tasks</Link>
          </li>
          {/* <li className={pathname.indexOf('settings') > -1 ? 'selected-option' : ''}>
            <Link to='/settings'>settings</Link>
          </li> */}
          {isGuest === true &&
            <li className={pathname.indexOf('login') > -1 ? 'selected-option' : ''}>
              <Link to='/login'>login</Link>
            </li>
          }
          {/* {isGuest === true &&
            <li className="register-option">
              <Link to='/register'>register</Link>
            </li>
          } */}
          {isGuest === false &&
            <li onClick={this.logout}>
              <a href='/'>logout</a>
            </li>
          }
        </ul>
      </div>
    );
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    isGuest: ServantSelectors.isGuest(state)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
