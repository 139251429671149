import { useHistory } from 'react-router-dom'
import './LegalFooter.css'

const LegalFooter = () => {
  const history = useHistory()

  const handleClick = route => {
    if (route === 'tos') {
      history.push('/tos')
    }
    if (route === 'privacy') {
      history.push('/privacypolicy')
    }
  }


  return (
    <div className="legal-footer-wrapper">
      <div className="legal-link" onClick={() => handleClick('tos')}>Terms of Service</div>
      <div className="legal-separator">|</div>
      <div className="legal-link" onClick={() => handleClick('privacy')}>Privacy Policy</div>
    </div>
  )
}

export default LegalFooter