import * as actions from '../../store/winston/Winston.reducer'

import { pointAtLoginButton } from './authentication'
import awake from './awake';
import clear from './clear'
import { userMustSignInForAction } from './help'
import welcome from './welcome';
// import backstory from './backstory.js';
// import help from './help.js';
// import idle from './idle.js';
// import intro from './intro.js';

const scenes = {
  // awake,
  // backstory,
  // help,
  // idle,
  // intro,
  [actions.pointAtSignIn]: pointAtLoginButton,
  [actions.clearDialog]: clear,
  [actions.signInToAddPursue]: userMustSignInForAction, 
  [actions.wakeFromIdle]: awake,
  [actions.welcomeMessage]: welcome
}

export default scenes