// Statuses for loading stages
export const ActionStatus = {
  Complete: 'Complete',
	Failed: 'Failed',
	Inactive: 'Inactive',
	Loading: 'Loading'
}

// Helper for creating all reducers
export function createReducer(initialState, handlers) {
	return function reducer(state = initialState, action) {
		if (handlers.hasOwnProperty(action.type)) {
			return handlers[action.type](state, action)
		} else {
			return state
		}
	}
}

// Returns if action is complete
export function isComplete(action) {
	return action === ActionStatus.Complete
}

// Returns boolean depending on if the action is in flight
export function finishedLoading(prevLoading, currentLoading) {
  return prevLoading === ActionStatus.Loading && currentLoading !== ActionStatus.Loading
}

// Returns boolean based on if action is inactive
export function isInactive(currentStatus) {
  return currentStatus === ActionStatus.Inactive
}

// Returns boolean based on if action is loading
export function isLoading(prevLoading, currentLoading) {
  return prevLoading !== ActionStatus.Loading && currentLoading === ActionStatus.Loading
}

export function uniqueArrayMerge(existing, newEntries) {
  return [
    ...existing,
    ...newEntries.filter(entry => !existing.includes(entry))
  ]
}

export function loading(state) {
	return state === ActionStatus.Loading
}