import * as Automations from './automations/Automations.selectors'
import * as Filters from './filters/Filter.selectors'
// import * as Friends from './friends/Friend.selectors'
import * as Pursue from './pursue/Pursue.selectors'
import * as Servant from './servant/Servant.selectors'
import * as Tasks from './tasks/Task.selectors'
import * as Winston from './winston/Winston.selectors'

export const AutomationSelectors = Automations
export const FilterSelectors = Filters
// export const FriendSelectors = Friends
export const PursueSelectors = Pursue
export const ServantSelectors = Servant
export const TaskSelectors = Tasks
export const WinstonSelectors = Winston