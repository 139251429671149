import { createAction, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import * as Api from './Task.api'
import { ActionStatus } from '../utils';


const taskAdapter = createEntityAdapter()
const initialState = taskAdapter.getInitialState({
  logs: [],
  nextTokens: {},
  tasks: [],
  recentlyCreated: null,
  claimTaskStatus: ActionStatus.Inactive,
  createTaskStatus: ActionStatus.Inactive,
  deleteTaskStatus: ActionStatus.Inactive,
  getTaskByIdStatus: ActionStatus.Inactive,
  getTasksStatus: ActionStatus.Inactive,
  updateTaskStatus: ActionStatus.Inactive
})

const handleTaskFilterAction = (dispatch, option, task) => {
  const createFilterAction = createAction(`CREATE_${option}_TASK_SUCCESS`)

  dispatch(createFilterAction({
    option,
    task
  }))
}
export const getTaskById = createAsyncThunk(
  'tasks/getTaskById',
  async taskId => {
    return Api.getTask(taskId)
  }
)

export const getLogs = createAsyncThunk(
  'tasks/getLogs',
  async taskId => {
    return Api.getLogs(taskId)
  }
)

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    claimTaskLoading(state) {
      state.claimTaskStatus = ActionStatus.Loading
    },
    claimTaskSuccess(state, action) {
      taskAdapter.addOne(
        state,
        action.payload
      )
      state.recentlyCreated = action.payload
      state.claimTaskStatus = ActionStatus.Complete
    },
    createTaskLoading(state) {
      state.createTaskStatus = ActionStatus.Loading
    },
    createTaskSuccess(state, action) {
      taskAdapter.addOne(state, action.payload)
      state.recentlyCreated = action.payload
      state.createTaskStatus = ActionStatus.Complete
    },
    deleteTaskLoading(state) {
      state.deleteTaskStatus = ActionStatus.Loading
    },
    deleteTaskSuccess(state, action) {
      taskAdapter.removeOne(state, action.payload)
      state.deleteTaskStatus = ActionStatus.Complete
    },
    getTasksLoading(state) {
      state.getTasksStatus = ActionStatus.Loading
    },
    getTasksSuccess(state, action) {
      const { filter, nextToken, tasks } = action.payload
      taskAdapter.addMany(state, tasks)
      state.getTasksStatus = ActionStatus.Complete
      if (filter) {
        state.nextTokens[filter] = nextToken
      }
    },
    updateTaskFailed(state, action) {
      state.updateTaskStatus = ActionStatus.Failed
      taskAdapter.upsertOne(state, action.payload)
    },
    updateTaskLoading(state, action) {
      state.updateTaskStatus = ActionStatus.Loading
      taskAdapter.upsertOne(state, action.payload)
    },
    updateTaskSuccess(state, action) {
      state.updateTaskStatus = ActionStatus.Complete
    }
  },
  extraReducers: {
    [getLogs.fulfilled]: (state, action) => {
      state.logs.push(action.payload)
    },
    [getTaskById.pending]: (state, action) => {
        state.getTaskByIdStatus = ActionStatus.Loading
    },
    [getTaskById.fulfilled]: (state, action) => {
      // because this wasnt fetched with a filter, it is not part of any filtered list
      // we dont show "all"
      taskAdapter.addOne(state, action)
      state.getTaskByIdStatus = ActionStatus.Complete
    }
  }
})

export const {
  claimTaskLoading,
  claimTaskSuccess,
  createTaskLoading,
  createTaskSuccess,
  deleteTaskLoading,
  deleteTaskSuccess,
  getTasksLoading,
  getTasksSuccess,
  updateTaskFailed,
  updateTaskLoading,
  updateTaskSuccess
} = taskSlice.actions

export default taskSlice.reducer

export const claimTask = claimData => async dispatch => {
  dispatch(claimTaskLoading())
  try {
    const claimedTask = await Api.claimTask(claimData)  
    dispatch(claimTaskSuccess(claimedTask))
    handleTaskFilterAction(dispatch, claimedTask.status, claimedTask)
  } catch(err) {
    console.log('CLAIM TASK ERROR: ', err)
  }
}

export const createTask = ({ option, task }) => async dispatch => {
  try {
    dispatch(createTaskLoading())
    const createdTask = await Api.createTask(task)

    dispatch(createTaskSuccess(createdTask))
    handleTaskFilterAction(dispatch, option, createdTask)
  } catch (err) {
    console.log('createTaskAction ERROR : ', err)
  }
}

export const deleteTask = task => async dispatch => {
  try {
    dispatch(deleteTaskLoading())

    await Api.deleteTask(task)

    const deleteFilterAction = createAction('DELETE_TASK_SUCCESS')
    dispatch(deleteFilterAction(task))

    dispatch(deleteTaskSuccess(task.id))
  } catch (err) {
    console.log('deleteTaskAction ERROR : ', err)
  }
}

export const getTasks = ({ options, params }) => async dispatch => {
  try {
    dispatch(getTasksLoading())
    
    const tasks = await Api.getTasks(params)

    const tasksWithIds = tasks.items.map(task => {
      return {
        ...task,
        id: task.id
      }
    })

    const payload = { tasks: tasksWithIds }

    if (tasks.nextToken ) {
      payload.filter = params.filter.status
      payload.nextToken = tasks.nextToken
    }

    // Dispatch array of IDs to filter reducer
    dispatch(getTasksSuccess(payload))
    
    // Create action for filter
    const getFilterAction = createAction(`GET_${options}_TASKS_SUCCESS`)
    // Dispatch all the entities to main task reducer
    dispatch(getFilterAction({
      nextToken: tasks.nextToken, 
      tasks: tasks.items
    }))
  } catch (err) {
    console.log('getTasksAction ERROR : ', err)
  }
}

export const updateTask = (task, oldTask) => async dispatch => {
  try {
    dispatch(updateTaskLoading(task))
    
    const updateFilterAction = createAction('UPDATE_TASK_SUCCESS')
    dispatch(updateFilterAction(task))
    
    await Api.updateTask(task)
    dispatch(updateTaskSuccess(task))
    
  } catch (err) {
    console.log('updateTaskAction ERROR : ', err)
    if (oldTask) {
      const updateFilterAction = createAction('UPDATE_TASK_SUCCESS')
      dispatch(updateFilterAction(oldTask))

      dispatch(updateTaskFailed(oldTask))
    }
  }
}