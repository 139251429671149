import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import './App.css';

import ToS from './Legal/Tos'
import PrivacyPolicy from './Legal/PrivacyPolicy'

import Authentication from './Authentication/Authentication';
import ClaimServant from './Automation/ClaimServant'
import { initExistingUser, initNewUser } from '../store/servant/Servant.reducer'
import { saveCode } from '../store/servant/Servant.reducer'
import { ServantSelectors } from '../store/selectors';
import TaskRouter from './Tasks/TaskRouter/TaskRouter';
import { isComplete } from '../store/utils';
class App extends Component {

  componentDidMount() {
    let tokenData = localStorage.getItem('servant');
    tokenData = tokenData ? JSON.parse(tokenData) : null;


    if (!tokenData || !tokenData.token) {
      this.props.initNewUser();
    } else {
      this.props.initExistingUser(tokenData.token);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.appInit && this.props.appInit && this.props.location.search.includes('?code=')) {
      const urlDecoded = decodeURIComponent(this.props.location.search)
      // eslint-disable-next-line no-useless-escape
      const codeRegex = /\=(.*?)\&/;
      const code = codeRegex.exec(urlDecoded)[1];
      this.props.saveCode({ code });
    }

    if (
      isComplete(this.props.saveCodeStatus) &&
      this.props.isGuest === false &&
      localStorage.getItem('AuthForwarding')?.length > 0
    ) {
      this.props.history.push(localStorage.getItem('AuthForwarding'))
      localStorage.removeItem('AuthForwarding')
    }
  }

  render() {
    const code = this.props.location.search.includes('?code=')
    if (this.props.appInit === false || code) {
      return null;
    }

    return (
      <div className="app-container">
        {/* Anything that is full screen must be a top layer router */}
        {/* Otherwise it will share the page with the sidebar */}
        <Switch>
          <Route path={`/task/:taskId/automation/claim`} component={ClaimServant} />
          <Route path='/login' component={Authentication} />
          <Route path='/tos' component={ToS} />
          <Route path='/privacypolicy' component={PrivacyPolicy} />
          <Route path='/' component={TaskRouter} />
        </Switch>
        {/* <LegalFooter /> */}
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    initExistingUser,
    initNewUser,
    saveCode
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    appInit: ServantSelectors.appInit(state),
    isGuest: ServantSelectors.isGuest(state),
    saveCodeStatus: ServantSelectors.getSaveCodeStatus(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
