import Event from '../'
import animations from '../animations'

const still = animations.animations.still.frames;


const ClearEvent = new Event();

ClearEvent.act({
  frames: still,
  speak: '',
  duration: 500
});

export default ClearEvent;