import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import jwt from 'jwt-simple'

import * as Api from './Servant.api'
import { ActionStatus } from '../utils';
import { updateGA } from '../utils/analytics'
import { saveCodeForPursueSuccessAction } from '../pursue/Pursue.reducer'

export const authWithGoogle = createAsyncThunk(
  'servant/authWithGoogle',
  async (data, { rejectWithValue }) => {
    const { profile } = data
    try {
      const { token } = await Api.authWithGoogle(profile)
      await Api.initToken(token)
      return { isGuest: false, token }
    } catch(err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const initExistingUser = createAsyncThunk(
  'servant/initExistingUser',
  async (token, { rejectWithValue }) => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`
      const servant = await Api.initExistingUser(token)
      if (!servant) {
        initNewUser()
      } else {
        updateGA(servant)
      }

      const isGuest = jwt.decode(token, '', true).guest
      return { isGuest, token }
    } catch(err) {
      return rejectWithValue(err)
    }
  }
)

export const initNewUser = createAsyncThunk(
  'servant/initNewUser',
  async (data, { rejectWithValue }) => {
    try {
      const servant = await Api.initNewUser()

      axios.defaults.headers.common['Authorization'] = `Bearer ${ servant.token }`

      localStorage.setItem('servant', JSON.stringify({
        token: servant.token
      }))

      const isGuest = jwt.decode(servant.token, '', true).guest

      updateGA(servant)

      return { isGuest, token: servant.token }
    } catch(err) {
      return rejectWithValue(err)
    }
  }
)

const servantSlice = createSlice({
  name: 'servant',
  initialState: {
    appInit: false,
    error: '',
    isGuest: null,
    token: null,
    loginStatus: ActionStatus.Inactive,
    registerStatus: ActionStatus.Inactive,
    saveCodeStatus: ActionStatus.Inactive
  },
  reducers: {
    logoutSuccess(state, action) {
      state.isGuest = true
    },
    loginLoading(state, action) {
      state.loginStatus = ActionStatus.Loading
    },
    loginSuccess(state, action) {
      state.loginStatus = ActionStatus.Complete
      state.appInit = true
      state.isGuest = action.payload
    },
    registerLoading(state, action) {
      state.saveCodeStatus = ActionStatus.Loading
    },
    registerSuccess(state, action) {
      state.registerStatus = ActionStatus.Complete
      state.appInit = true
      state.isGuest = action.payload
    },
    saveCodeLoading(state, action) {
      state.saveCodeStatus = ActionStatus.Loading
    },
    saveCodeSuccess(state, action) {
      state.saveCodeStatus = ActionStatus.Complete
    }
  },
  extraReducers: {
    [authWithGoogle.pending]: (state, action) => {
      state.loginStatus = ActionStatus.Loading
    },
    [authWithGoogle.fulfilled]: (state, action) => {
      state.appInit = true;
      state.isGuest = action.payload.isGuest
      state.loginStatus = ActionStatus.Complete
      state.token = action.payload.token
    },
    [initExistingUser.fulfilled]: (state, action) => {
      state.appInit = true
      state.isGuest = action.payload.isGuest
      state.token = action.payload.token
    },
    [initNewUser.fulfilled]: (state, action) => {
      state.appInit = true
      state.isGuest = action.payload.isGuest
      state.token = action.payload.token
    }
  }
})

export const {
  loginLoading,
  loginSuccess,
  logoutSuccess,
  registerLoading,
  registerSuccess,
  saveCodeLoading,
  saveCodeSuccess
} = servantSlice.actions

export default servantSlice.reducer

export const login = ({ email, password }) => async dispatch => {
  try {
    dispatch(loginLoading())
    const { token } = await Api.login(email, password)
    await Api.initToken(token)
    dispatch(loginSuccess())
  } catch (err) {
    console.log('loginAction ERROR : ', err)
  }
}

export const logout = () => async dispatch => {
  axios.defaults.headers.common['Authorization'] = ''
  localStorage.removeItem('servant')
  dispatch(logoutSuccess)
}

export const register = ({ email, password }) => async dispatch => {
  try {
    dispatch(registerLoading())
    const { token } = await Api.register(email, password)
    await Api.initToken(token)
    dispatch(registerSuccess())
  } catch (err) {
    console.log('registerAction ERROR : ', err)
  }
}

export const saveCode = ({ code }) => async dispatch => {
  try {
    dispatch(saveCodeLoading())
    const { access_token, servant_token } = await Api.saveCode(code)
    const servantToken = servant_token ? servant_token : JSON.parse(localStorage.getItem('servant')).token

    dispatch(saveCodeSuccess())
    dispatch(saveCodeForPursueSuccessAction(access_token))
    dispatch(initExistingUser(servantToken))
  } catch (err) {
    console.log('SAVE CODE ERROR : ', err)
  }
}
