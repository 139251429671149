import { createSelector } from 'reselect'

import { FILTER_VALUES } from '../../types/taskFilters.types'
import { sortTasksByDate, tasksByDate } from './Task.utils'
import { taskFilter } from '../filters/Filter.selectors'

export const claimTaskStatus = state => state.tasks.claimTaskStatus
export const completeTasks = state => state.tasksFilteredByComplete
export const createTaskStatus = state => state.tasks.createTaskStatus
export const deleteTaskStatus = state => state.tasks.deleteTaskStatus
export const getTaskByIdStatus = state => state.tasks.getTaskByIdStatus
export const getTasksStatus = state => state.tasks.getTasksStatus
export const updateTaskStatus = state => state.tasks.updateTaskStatus

export const allTasks = state => state.tasksFilteredByAll
export const incompleteTasks = state => state.tasksFilteredByIncomplete
export const overdueTasks = state => state.tasksFilteredByOverdue
export const nextTokens = state => state.tasks.nextTokens
export const recentlyCreatedTask = state => state.tasks.recentlyCreated
export const unfilteredTasks = state => state.tasks.entities

export const getAllLogs = state => state.tasks.logs

// Helper fn that builds the object for task entities and the next token string
const returnTaskInfo = (entities, filteredTasks) => {
  const tasks = filteredTasks.ids.map(id => entities[id])
  return {
    tasks,
    token: filteredTasks.nextToken
  }
}

// These will return the task ids associated with the filter
export const allTasksData = createSelector(
  unfilteredTasks,
  allTasks,
  (unfiltered, filteredTasks) => returnTaskInfo(unfiltered, filteredTasks)
)

export const completeTasksData = createSelector(
  unfilteredTasks,
  completeTasks,
  (unfiltered, filteredTasks) => returnTaskInfo(unfiltered, filteredTasks)
)

export const incompleteTasksData = createSelector(
  unfilteredTasks,
  incompleteTasks,
  (unfiltered, filteredTasks) => returnTaskInfo(unfiltered, filteredTasks)
)

export const overdueTasksData = createSelector(
  unfilteredTasks,
  overdueTasks,
  (unfiltered, filteredTasks) => returnTaskInfo(unfiltered, filteredTasks)
)

// Finds out what filter is selected on the front end
const getTaskType = createSelector(
  taskFilter,
  allTasksData,
  completeTasksData,
  incompleteTasksData,
  overdueTasksData,
  (filter, all, complete, incomplete, overdue) => {
    switch(filter) {
      case FILTER_VALUES.all:
          return all

      case FILTER_VALUES.complete:
        return complete
        
        case FILTER_VALUES.incomplete:
          return incomplete

      case FILTER_VALUES.overdue:
          return overdue
  
      default:
        return {}
    }
  }
)

export const getNextToken = createSelector(
  nextTokens,
  taskFilter,
  (tokens, filter) => {
    switch(filter) {
      case FILTER_VALUES.all:
        return tokens[FILTER_VALUES.all]
      case FILTER_VALUES.complete:
        return tokens[FILTER_VALUES.complete]
      case FILTER_VALUES.incomplete:
        return tokens[FILTER_VALUES.incomplete]
      case FILTER_VALUES.overdue:
        return tokens[FILTER_VALUES.overdue]
      default:
        return {}
    }
  }
)


// Returns the tasks for the selected filter
export const getTasks = createSelector(
  getTaskType,
  (type) => type.tasks
)

// Returns the token for the filter (token to get next X amount of tasks)
export const getToken = createSelector(
  getTaskType,
  (type) => type.token
)

export const getTasksByDate = createSelector(
  getTasks,
  (tasks) => tasksByDate(sortTasksByDate(tasks))
)

// Used to get the task id out of the URL and return that task's data
export const getTaskById = createSelector(
  unfilteredTasks,
  // state: redux store
  // props: connected component's props
  (state, props) => {
    return ({
    taskId: props.match.params.taskId
  })},
  (unfiltered, { taskId }) => {
    return unfiltered[taskId]
  }
)

export const getLogsForTask = createSelector(
  getAllLogs,
  (state, props) => ({
    taskId: props.match.params.taskId
  }),
  (logs, { taskId }) => {
    return logs.filter(log => {
      return log.task_id === taskId
    })
  }
)

// sort by extension/automation (the actual pursue automation - all pursue instances)
// sort by date
// sort by instance id (chunked by automation instance)