import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as Api from './Automations.api'

export const getAutomations = createAsyncThunk(
  'automations/getAutomations',
  async (data, thunkAPI) => {
    const response = await Api.getAutomations()
    return response
  }
)

const automationSlice = createSlice({
  name: 'automations',
  initialState: {
    automations: [],
    nextToken: null
  },
  reducers: {
    clearAutomations: state => {
      state.automations = []
    }
  },
  extraReducers: {
    [getAutomations.fulfilled]: (state, action) => {
      state.automations = action.payload.data
      state.nextToken = action.payload.nextToken
    }
  }
})

export default automationSlice.reducer