import axios from 'axios'

function getParamsForGetTask(params) {
  let result = '?sort=asc'
  if (params.filter?.status && params.filter.status !== 'all') {
    result += `&filter[status]=${params.filter.status}`
  }
  if (params.after) {
    result += `&after=${params.after}`
  }

  if (params.limit) {
    result += `&limit=${params.limit}`
  } else {
    result += '&limit=50'
  }
  return result
}

export async function claimTask(claimData) {
  const { data } = await axios.post('/tasks/claim', {
    ...claimData
  })
  return data
}

export async function createTask(task) {
  const { data } = await axios.post('/tasks', {
    ...task
  })
  return data
}

export async function deleteTask(task) {
  await axios.delete(`/tasks/${task.id}`)
}

export async function getTask(id) {
  const { data } = await axios.get(`/tasks/${id}`)
  return data
}

export async function getTasks(params) {
  const queryParams = getParamsForGetTask(params)
  const { data } = await axios.get(`/tasks${queryParams}`)
  return data
}

export async function updateTask(task) {
  const { data } = await axios.put(`/tasks/${task.id}`, {
    ...task
  })
  return data
}

export async function getLogs(taskId) {
  const { data } =  await axios.get(`/tasks/${taskId}/logs`)
  return data


  // const { data } = axios.post('https://1km544751c.execute-api.us-east-1.amazonaws.com/prod/logs', {
  //     "task_id": taskId,
  //     "extension_id": "extension_id",
  //     "instance_id": "123",
  //     "message": "Activity Log Message 4",
  //     "meta": {
  //       "metaProp": "Here is some metadata"
  //     }
  // })
}

