import React, { useState } from 'react'
import Winston from './Winston'

function WinstonFull() {
  const [ displayWinston ] = useState(true)

  return (
    displayWinston && (
      <div
        className="winston-full fadeIn"
        // onAnimationEnd={() => setDisplay(false)}
      >
        <Winston />
      </div>
    )
  )
}

export default WinstonFull