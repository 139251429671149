import _ from 'lodash'
import { normalize } from 'normalizr'

import { ActionStatus, uniqueArrayMerge } from '../utils'
import { taskSchema } from '../schemas'

function createTaskFilter(state, action, includeEntities = true) {
  const normalized = normalize(action.payload.task, taskSchema)
  const newState = {
    ids: uniqueArrayMerge(state.ids, [normalized.result])
  }
  
  if (includeEntities) {
    newState.entities = {
      ...state.entities,
      ...normalized.entities.tasks
    }
    newState.createTaskStatus = ActionStatus.Complete
    newState.recentlyCreated = action.payload.task
  }

  return {
    ...state,
    ...newState
  }
}

function deleteTaskFilter(state, action, includeEntities = true) {
  const newState = {
    ids: _.without(state.ids, action.payload.id)
  }
  if (includeEntities) {
    newState.entities = _.omit(state.entities, action.payload.id)
    newState.deleteTaskStatus = ActionStatus.Complete
  }

  return {
    ...state,
    ...newState
  }
}

function getTasksFilter(state, action, includeEntities = true, includeToken = false) {
  const normalized = normalize(action.payload.tasks, [taskSchema])
  const newState = {
    ids: uniqueArrayMerge(state.ids, normalized.result)
  }

  if (includeEntities) {
    newState.entities = {
      ...state.entities,
      ...normalized.entities.tasks
    }
  }
  if (includeToken) {
    newState.nextToken = action.payload.nextToken
  }

  return {
    ...state,
    ...newState
  }
}

function updateTaskFilter(state, action) {
  const normalized = normalize(action.payload, taskSchema)

  return {
    ...state,
    entities: {
      ...state.entities,
      ...normalized.entities.tasks
    },
    ids: uniqueArrayMerge(state.ids, [normalized.result]),
  }
}

const taskFiltersInitialState = {
  ids: [],
  nextToken: null
}

export function createTaskFilterReducer(filterName = '') {
  return function taskFilterReducer(state = taskFiltersInitialState, action) {
    switch(action.type) {
      case `CREATE_${filterName}_TASK_SUCCESS`:
        return createTaskFilter(state, action, false)

      case `DELETE_TASK_SUCCESS`:
        return deleteTaskFilter(state, action, false)

      case `GET_${filterName}_TASKS_SUCCESS`:
        return getTasksFilter(state, action, false, true)

      case `UPDATE_TASK_SUCCESS`:
        return updateTaskFilter(state, action, filterName)

      default:
        return state
    }
  }
}