import React, { Component } from 'react'
import { Switch, Route } from 'react-router'

import CreatePursue from '../TaskAutomations/Pursue/CreatePursue'
import NewPursue from '../TaskAutomations/Pursue/PursueModalContainer'
import Sidebar from '../../Sidebar/Sidebar'
import TaskDetails from '../TaskDetails/TaskDetails'
import TaskIndex from '../TaskIndex/TaskIndex'
import WinstonFull from '../../Winston/WinstonFull'

class TaskRouter extends Component {
  render() {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="panel-container">
          <div className='s-divider'/>
          <TaskIndex />
          <Switch>
            <Route exact path={`/`} component={WinstonFull} />
            <Route path={`/task/:taskId/automation/pursue/create`} component={CreatePursue} />
            <Route path={`/task/:taskId/automation/pursue`} component={NewPursue} />
            <Route path={`/task/:taskId`} component={TaskDetails}/>
          </Switch>
        </div>
      </React.Fragment>
    )
  }
}

export default TaskRouter
