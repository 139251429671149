import React from 'react'
import ContentEditable from 'react-contenteditable'

const Textarea = React.forwardRef((props, ref) => {
  const handleChange = event => {
    props.handleChange(
      event.target.value || '',
      props.index
    )
  }

  const handleKeyDown = event => {
    const { keyPressTriggers } = props
    if (keyPressTriggers?.length > 0 && keyPressTriggers.indexOf(event.key) > -1) {
      props.handleSubmit()
    }
  }

  const hitEnter = '<div><br></div><div><br></div>'
  const html = props.html === hitEnter ? '' : props.html

  return (
    <div className={`s-textarea-wrapper`}>
      <ContentEditable
        className={`s-textarea-input ${props.className}`}
        html={html || ''} // innerHTML of the editable div
        onChange={handleChange} // handle innerHTML change
        placeholder={props.placeholder}
        onKeyDown={handleKeyDown}
        onBlur={props.onBlur}
        innerRef={ref}
      />
    </div>
  )
})

export default Textarea